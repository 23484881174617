import firebase from "../util/firebase";
const firestore = firebase.firestore();

export const getUserDetails = async (id, cb) => {
  firestore
    .collection("users")
    .doc(id)
    .get()
    .then((doc) => {
      if (doc.exists) {
        console.log(doc.data(), "getUserDetails");
        const data = {
          ...doc.data(),
          id: doc.id,
        };
        cb(data, "success");
        return data;
      } else {
        cb({}, "nodoc");
      }
    })
    .catch((e) => {
      cb(e, "error");
      console.log("getUserDetails", e);
    });
};
