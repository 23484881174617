import React, { useState, useEffect } from "react";
import firebase from "util/firebase";
import toast from "react-hot-toast";

// Create a root reference
var storageRef = firebase.storage().ref();

const useUploadFile = (path, fileName) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [url, setUrl] = useState("");
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState("pending");
  const [fileData, setFileData] = useState(null);
  const [type, setType] = useState("");

  useEffect(() => {
    if (!path) {
      toast.error("Upload Path Missing ");
    }
  }, []);

  // this function will be called when the any properties in the dependency array changes
  useEffect(() => {
    if (fileData instanceof Blob || fileData instanceof File) {
      uploadToFirebase();
    }
  }, [fileData]);

  const uploadToFirebase = async () => {
    setError(null);
    setUploading("pending");
    setUploadProgress(0);
    setUrl("");

    // Create the file metadata
    var metadata = {
      contentType: "image/jpeg",
    };

    let fName = fileName
      ? fileName
      : `${new Date().getTime()}-${fileData?.name}`;
    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef.child(path + fName).put(fileData, metadata);

    // Listen for state changes, errors, and completion of the upload.
    await uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function (snapshot) {
        setUploading("started");
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setUploadProgress(parseInt(progress));
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            setUploading("paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            setUploading("running");
            break;
          default:
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      function (error) {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        setError(error);
        setUploading("error");
        toast.error(error?.code);
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;

          case "storage/canceled":
            // User canceled the upload
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
          default:
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      function () {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then(async function (downloadURL) {
            await setUrl(downloadURL);
            setUploading("completed");
            console.log("File available at", downloadURL);
            console.log("completed");
            toast.success("Upload Completed");
          });
      }
    );
  };

  return {
    uploadProgress,
    url,
    error,
    uploading,
    type,
    setType,
    setFileData,
    setUrl,
  };
};

export default useUploadFile;
