import React, { useState, useEffect } from "react";
// import Section from "../../Section";
// import Container from "react-bootstrap/Container";
// import { Link } from "../../Link";

import "./ProductForm.scss";
// import { Button } from "react-bootstrap";
import config from "../../../config/config";
// import { useSelector, useDispatch } from "react-redux";
import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { AppContext } from "../../../config/context/AppContext";
import { useRouter } from "../../../util/router";
import {
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import toast from "react-hot-toast";

export interface ProductFormProps {
  product?: object;
  productId: string;
  name: string;
  desc?: string;
  images?: string[];
  designer?: string;
  designerId?: string;
  price: number;
  size: string[];
}
// const { Option } = Select;

function ProductForm({
  product,
  name,
  desc,
  designer,
  designerId,
  images,
  price,
  productId,
  size,
}: ProductFormProps) {
  // const dispatch = useDispatch();
  // const state = useSelector((state: any) => state);
  const router = useRouter();
  const { appState, appReducer } = React.useContext(AppContext) as any;

  const [quantity, setQuantity] = useState<any>(1);
  const [selectedSize, setSelectedSize] = useState<any>("XL");
  const [printSide, setPrintSide] = useState<string>("Front");
  const [color, setColor] = useState<any>("black");
  const [quantityArr, setQuantityArr] = useState<any>({ quantity: {} });
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const val = appState?.config?.find((i: any) => i.color === color);
    setQuantityArr(val);
    setLoading(false);
  }, [setColor, color]);
  // console.log(state);

  const addToCart = async () => {
    if (quantity && selectedSize) {
      await appReducer({
        type: "add_item",
        payload: {
          item: {
            ...product,
            name,
            desc,
            designer,
            designerId,
            price,
            color,
            productId,
            size,
            printSide,
            images,
            orderedQuantity: quantity,
            selectedSize,
            itemTotal: price * quantity,
          },
        },
      });

      router.push("/cart");
    } else toast.error("Please select the required");
    // console.log(state);
  };

  return (
    <>
      <div className="formContainer">
        <h1
          style={{
            fontWeight: "bold",
            marginBottom: 0,
            marginTop: 20,
            paddingBottom: 0,
          }}
        >
          {name}
        </h1>
        {designer ? (
          <h4 style={{ marginTop: 0, paddingTop: 0 }}>
            {config.sticker_form.by} {designer}
          </h4>
        ) : null}
        {desc ? <h5 style={{ maxWidth: 400 }}>{desc}</h5> : null}
        <h2 style={{ marginBottom: 0 }}>
          Rs. {price}/{config.sticker_form.piece}
        </h2>
        <div style={{ display: "flex" }}>
          {appState?.config?.map((i: any) => (
            <div
              style={{
                margin: 10,
                marginLeft: 0,
                marginRight: 20,
                width: 50,
                height: 50,
                borderRadius: 25,
                backgroundColor: i.color,
                borderWidth: color === i.color ? 5 : 0,
                borderStyle: "solid",
                borderColor: "blue",
                boxShadow: "3px 3px 6px 0px grey",
                cursor: "pointer",
              }}
              onClick={() => {
                setColor(i.color);
                setQuantity(1);
              }}
            />
          ))}
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              margin: 10,
              marginLeft: 0,
              marginRight: 10,
              width: 80,
              height: 30,
              borderRadius: 25,
              // borderWidth: 2,
              borderStyle: "solid",
              borderColor: printSide === "Front" ? "blue" : "white",
              boxShadow: "3px 3px 6px 0px grey",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setPrintSide("Front");
            }}
          >
            Front
          </div>
          <div
            style={{
              margin: 10,
              marginLeft: 0,
              width: 80,
              height: 30,
              borderRadius: 25,
              // borderWidth: 2,
              borderStyle: "solid",
              borderColor: printSide === "Back" ? "blue" : "white",
              boxShadow: "3px 3px 6px 0px grey",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setPrintSide("Back");
            }}
          >
            Back
          </div>
        </div>
        {/* <Row> */}
        <h4 style={{ marginTop: 20, marginBottom: 10 }}>
          {config.sticker_form.selectSize}
        </h4>
        <FormControl className="sizeSelect">
          {/* <InputLabel id="quantity">
            {config.sticker_form.selectSize}
          </InputLabel> */}
          {!loading ? (
            <Select
              labelId="quantity"
              value={selectedSize ? selectedSize : ""}
              onChange={(e) => {
                setSelectedSize(e.target.value);
                setQuantity(1);
              }}
              style={{ backgroundColor: "transparent" }}
              // placeholder={config?.sticker_form.selectSize}
            >
              {Object.keys(quantityArr?.quantity).map((i: any) => (
                <MenuItem value={i}>{i}</MenuItem>
              ))}
            </Select>
          ) : null}
        </FormControl>
        <h4 style={{ marginTop: 20, marginBottom: 0 }}>
          {config.sticker_form.selectQuantity}
        </h4>

        {quantityArr?.quantity[selectedSize] !== 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "center",
              borderRadius: 30,
              height: 60,
              width: 160,
              borderWidth: 2,
              // borderStyle: "solid",
              // borderColor: "black",
            }}
          >
            <MinusCircleTwoTone
              style={{ fontSize: 30 }}
              onClick={() => {
                if (quantity === 1) return;
                setQuantity(quantity - 1);
              }}
            />
            <h1 style={{ margin: 20, marginTop: 0, marginBottom: 0 }}>
              {quantity}
            </h1>
            <PlusCircleTwoTone
              style={{ fontSize: 30 }}
              onClick={() => {
                if (
                  quantity >= 10 ||
                  quantity === quantityArr?.quantity[selectedSize]
                )
                  return;

                setQuantity(quantity + 1);
              }}
            />
          </div>
        ) : (
          <h2>Out Of Stock</h2>
        )}
        {/* <Select
          size={"large"}
          onChange={(e) => {
            setQuantity(e);
          }}
          placeholder={config.sticker_form.selectQuantity}
          style={{ width: "80%" }}
          allowClear
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
            <Option key={i.toString()} value={i}>
              {i.toString()}
            </Option>
          ))}
        </Select> */}
        {/* </Row> */}
        <Button
          variant="contained"
          color="primary"
          title="Add"
          className="addToCartbtn"
          style={{ marginTop: 30, borderRadius: 30 }}
          onClick={addToCart}
        >
          {config.sticker_form.addToCart}
        </Button>
      </div>
    </>
  );
}

export default ProductForm;
