import React, { useContext, useState } from "react";
import OrderItem from "../../components/Ecommerce/OrderItem/OrderItem";
// import { requireAdminAuth } from "../../util/auth";
import { requireAdminAuth } from "../../util/auth";
import firebase from "../../util/firebase";
// import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { UserContext } from "../../config/context/UserContext";
import { AppContext } from "../../config/context/AppContext";
import TableComponent from "../../components/Reusable/Table";
import { makeStyles } from "@material-ui/core";

const db = firebase.firestore();

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minHeight: "100vh",
      [theme.breakpoints.down("sm")]: {
        marginTop: 50,
        padding: 10,
      },
    },
  };
});

export interface UserDetailsProps {}
function UserDetails(props: any) {
  const classes = useStyles();
  const { appState, appReducer } = useContext(AppContext) as any;
  const { userState, userReducer } = useContext(UserContext) as any;

  //Props
  const userId = props?.match?.params?.id || props?.id;
  console.log(userId);

  return (
    <>
      <div className={classes.root}></div>
    </>
  );
}

// export default requireAdminAuth(UserDetails);
export default UserDetails;
