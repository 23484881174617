import React, { useState } from "react";
// import Section from "../../Section";
// import { Link } from "../../Link";
import { Select } from "antd";
import "./OrderItem.scss";
import { Container, Col, Row } from "react-bootstrap";
import { DeleteOutlined } from "@ant-design/icons";
import config from "../../../config/config";
import moment from "moment";
import Column from "antd/lib/table/Column";
import { Chip } from "@material-ui/core";

export interface OrderItemProps {
  order?: any;
  click: () => void;
}

function OrderItem({ order, click }: OrderItemProps) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div
        style={{
          padding: 10,
          margin: 10,
          marginBottom: 20,
          borderRadius: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow:
            "0  5px 10px rgba(154,160,185,0.05),  0 15px 40px rgba(166,173,201,0.2)",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
          onClick={() => setOpen(!open)}
        >
          <h4>{order?.orderId}</h4>
          <h4>{moment(order?.createdAt.toDate()).format("DD MMM YYYY")}</h4>
          <Chip
            size="small"
            // avatar={<Avatar>M</Avatar>}
            label={order?.status}
            style={{
              color: order?.status === "Pending" ? "black" : "white",
              backgroundColor:
                order?.status === "Rejected"
                  ? "#f5365c"
                  : order?.status === "Pending"
                  ? "#ffeaa7"
                  : order?.status === "InProcess"
                  ? "#fdcb6e"
                  : order?.status === "Shipped"
                  ? "#0984e3"
                  : order?.status === "Delivered"
                  ? "#00b894"
                  : "white",
            }}
          />

          <h4>Rs {order?.bill?.grandTotal}</h4>
        </div>
        <div
          style={{
            height: open ? 400 : 0,
            opacity: open ? 1 : 0,
            transition: "all 0.35s ease",
            width: "100%",
            overflowY: "scroll",
            borderRadius: 8,
            backgroundColor: "#f7f7fc",
          }}
        >
          {order.lineItems.map((i: any) => {
            return (
              <div
                style={{
                  margin: 10,
                  padding: 10,
                  borderRadius: 8,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // backgroundColor: "white",
                }}
              >
                <img
                  src={i.product.images[0]}
                  alt=""
                  style={{ width: 150, borderRadius: 8 }}
                />
                <div style={{ marginLeft: 150 }}>
                  <h4>{i.product.name}</h4>
                  <h5>by {i.product.designer}</h5>
                  <h5>Price: {i.product.price}/pc</h5>
                </div>
                <div style={{ marginLeft: 150 }}>
                  <h5>Quantity: {i.product.orderedQuantity}</h5>
                  <h5>Size: {i.product.selectedSize}</h5>
                  <h5>Print on: {i.product?.printSide}</h5>
                </div>
                <div style={{ marginLeft: 150 }}>
                  <h5>SubTotal</h5>
                  <h4>Rs. {i.product.itemTotal}</h4>
                </div>
              </div>
            );
          })}
        </div>
        {open ? (
          <div
            style={{
              width: "100%",
              paddingTop: 10,
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h4>SubTotal: {order?.bill.subTotal}</h4>
            <h4>
              Shipping:
              {order.bill.shipping}
            </h4>
            <h4>GST: {order?.bill?.taxAmount}</h4>
            <h4>Total: Rs {order?.bill?.grandTotal}</h4>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default OrderItem;
