import React, { useEffect, useState } from "react";
import CartItem from "../components/Ecommerce/CartItem/CartItem";
import Checkoutform from "../components/Ecommerce/Checkout/Checkout";
import cart from "../assets/empty-cart.png";
import { UserContext } from "../config/context/UserContext";
import { AppContext } from "../config/context/AppContext";
import { CheckoutProvider, Checkout } from "paytm-blink-checkout-react";
import axios from "axios";
import InjectedCheckout from "./InjectedCheckout";
import GooglePayButton from "@google-pay/button-react";
import AddressForm from "../components/Ecommerce/AddressForm/AddressForm";
import { Divider, Grid, Paper, Typography, Button } from "@material-ui/core";
import MaterialAccordion from "../components/Reusable/MaterialAccordion";
import { useRouter } from "../util/router";
import { createOrder } from "../config/OrderQueries";
import SidePanel from "../components/Reusable/SidePanel";
import { v4 as uuid } from "uuid";
import Loader from "../components/Reusable/Loader";
import toast from "react-hot-toast";
import { getRazorpayInstance, payWithRazorpay } from "../config/Hooks/razorpay";

export interface CartPageProps {}

const USE_EXISTING_CHECKOUT_INSTANCE = "Use existing checkout instance : ";

function CartPage(props: CartPageProps) {
  const { userState, userReducer } = React.useContext(UserContext) as any;
  const { appState, appReducer } = React.useContext(AppContext) as any;

  const router = useRouter();

  const [token, setToken] = useState<any>(null);
  const [showCheckout, setShowCheckout] = useState<boolean>(false);
  const [checkoutJsInstance, setCheckoutJsInstance] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showPanel, setShowPanel] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<object>({}) as any;
  const [total, setTotal] = useState<number>(0) as any;
  const [itemCount, setItemCount] = useState<number>(0) as any;

  useEffect(() => {
    userReducer({ action: "GET_ADDRESS", payload: null });
  }, []);

  useEffect(() => {
    //Total Calculation
    if (appState?.cartItems.length > 0) {
      var pricetotal = appState?.cartItems.map(
        (item) => item.price * item.orderedQuantity
      );
      var beforePricetotal = appState?.cartItems.map((item) => {
        if (item?.beforePrice || item?.beforePrice != 0) {
          return item.beforePrice * item.orderedQuantity;
        } else {
          return item.price * item.orderedQuantity;
        }
      });

      var total = pricetotal.reduce((prev, curr) => prev + curr);
      var beforetotal = beforePricetotal.reduce((prev, curr) => prev + curr);

      var orderedQuantity = appState?.cartItems.map(
        (item) => item.orderedQuantity
      );
      var count = orderedQuantity.reduce((prev, curr) => prev + curr);
      setTotal(total);
      setItemCount(count);
    }
  }, [appState?.cartItems]);

  const checkOut = async (payment) => {
    if (!appState?.userDetails?.uid && !appState?.userDetails?.email) {
      toast.error("Please login to place an order");
      return;
    }
    if (Object.keys(selectedAddress).length === 0) {
      toast.error("Please Select an address");
      return;
    }
    setIsSubmitting(true);
    const userDetails = {
      uid: appState?.userDetails?.uid,
      email: appState?.userDetails?.email,
      name: appState?.userDetails?.name,
      mobile: appState?.userDetails?.mobile,
    };
    const lineItems: any = await createProductArray();
    console.log(lineItems);

    const success = async () => {
      await createOrder(
        userDetails,
        selectedAddress,
        lineItems?.products,
        lineItems?.productsIds,
        lineItems?.designers,
        total
      );
      appReducer({
        type: "SET_ORDER_ITEMS",
        payload: null,
      });
      router.push("/thankyou");
    };

    await payWithRazorpay(total + 50, setIsSubmitting, success);
  };

  const createProductArray = () => {
    const products: any = [];
    const designers: any = [];
    const productsIds: any = [];
    let productArray = appState?.cartItems.slice();
    productArray.map((product, i) => {
      // console.log(product, '*******')
      let item: any = {
        id: uuid(),
        index: i,
        product: {
          id: product.id,
          category: product.category,
          images: product.images,
          productId: product.productId,
          name: product.name,
          desc: product.desc || "",
          selectedSize: product.selectedSize,
          printSide: product.printSide,
          orderedQuantity: product.orderedQuantity,
          price: product.price,
          designer: product.designer,
          designerId: product.designerId,
          color: product.color,
          itemTotal: product.itemTotal,
          // packageDimension: product.packageDimension,
          // shippingWeight: product?.shippingWeight,
        },
        shared: false,
        deliveryStatus: "Pending",
        cancelled: false,
        returned: false,
        refundAmount: 0,
        returnShipmentId: null,
        returnShipmentCost: "",
        _type: "orderItem",
      };
      products.push(item);
      productsIds.push(product.id);
      designers.push(product?.designerId);
    });

    return { products, designers, productsIds };
  };

  const orderId = "ORDERID_" + Math.random().toString(36).substring(5);

  // useEffect(() => {
  //   if (!token) {
  //     getRazorpayInstance(orderId, setToken);
  //   }
  //   return () => {};
  // }, []);

  const config = {
    root: "",
    flow: "DEFAULT",
    data: {
      orderId: orderId,
      token: token?.txnToken,
      tokenType: "TXN_TOKEN",
      amount: "1",
    },
    merchant: {
      mid: "mpjEyx70409295419705",
      name: "UrbanKlozet",
      redirect: false,
    },
    handler: {
      notifyMerchant: (eventName, data) => {
        console.log("notifyMerchant handler function called");
        console.log("eventName => ", eventName);
        console.log("data => ", data);
        if (eventName == "SESSION_EXPIRED") {
          console.log("SESSION_EXPIRED");
          // location.reload();
        }
      },
      transactionStatus: function (data) {
        console.log("payment status ", data);
        // window?.Paytm?.CheckoutJS.close();
        var result = "<h2>Response: </h2><table>";
        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            result += "<tr><td>" + key + "</td><td>" + data[key] + "</td></tr>";
          }
        }
        result += "</table>";
        console.log(result);
        // document?.getElementById("blink-response").innerHTML = result;
      },
    },
  };

  const renderPaymentForm = () => {
    console.log("Loading Form");
    const script = document.createElement("script");
    script.src =
      `https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/` +
      "WnRJve88509533102565.js";
    // script.src = `https://test.oppwa.com/v1/checkouts/${checkoutId}/registration`;
    script.async = true;
    script.type = "application/javascript";
    script.crossOrigin = "anonymous";
    script.onload = async () => {
      console.log("loaded");

      const data: any = window;

      if (data.Paytm && data.Paytm.CheckoutJS) {
        data.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
          // initialze configuration using init method
          data.Paytm.CheckoutJS.init({
            root: "",
            flow: "DEFAULT",
            data: {
              orderId: orderId,
              token: token?.txnToken,
              tokenType: "TXN_TOKEN",
              amount: 1,
            },
            payMode: {
              labels: {},
              filter: [],
              order: ["LOGIN", "CARD", "NB", "UPI"],
            },
            handler: {
              notifyMerchant: function (eventName, data) {
                console.log("notifyMerchant handler function called");
                console.log("eventName => ", eventName);
                console.log("data => ", data);
                if (eventName === "SESSION_EXPIRED") {
                  console.log("SESSION_EXPIRED");
                  // location.reload();
                }
              },
            },
          })
            .then(function onSuccess() {
              // after successfully updating configuration, invoke JS Checkout
              data.Paytm.CheckoutJS.invoke();
            })
            .catch(function onError(error) {
              console.log("error => ", error);
            });
        });
      }
    };
    // script.onerror = () => {
    //   console.log("error");
    // };
    document.body.appendChild(script);
    const form = document.createElement("form");
    // form.action = `https://fitting-room-3d548.web.app/paymentResult?uid=${val.uid}`;
    // window.Option = {
    //   style: "plain",
    //   // billingAddress: {},
    //   // mandatoryBillingFields: {
    //   //   country: true,
    //   //   state: true,
    //   //   city: true,
    //   //   postcode: true,
    //   //   street1: true,
    //   //   street2: false,
    //   // },
    // };
    // form.setAttribute("class", "paymentWidgets");
    // form.setAttribute("data-brands", "MADA VISA MASTER");
    document.body.appendChild(form);
  };

  // const loadCheckoutScript = () => {
  //   let MID = "mpjEyx70409295419705";
  //   const url = `https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/`;
  //   const scriptElement = document.createElement("script");
  //   scriptElement.async = true;
  //   scriptElement.src = url.concat("mpjEyx70409295419705.js");
  //   scriptElement.type = "application/javascript";
  //   scriptElement.onload = () => {
  //     const checkoutJsInstance = getCheckoutJsObj();

  //     if (checkoutJsInstance && checkoutJsInstance.onLoad) {
  //       checkoutJsInstance.onLoad(() => {
  //         setCheckoutJsInstance(checkoutJsInstance);
  //       });
  //     } else {
  //       console.error(USE_EXISTING_CHECKOUT_INSTANCE + "onload not available!");
  //     }
  //   };
  //   scriptElement.onerror = (error) => {
  //     console.error(USE_EXISTING_CHECKOUT_INSTANCE + "script load fail!");
  //   };
  //   document.body.appendChild(scriptElement);
  // };

  // const getCheckoutJsObj = () => {
  //   const data: any = window;
  //   if (data && data?.Paytm && data?.Paytm?.CheckoutJS) {
  //     return data.Paytm.CheckoutJS;
  //   } else {
  //     console.error(
  //       USE_EXISTING_CHECKOUT_INSTANCE + "Checkout instance not found!"
  //     );
  //   }

  //   return null;
  // };

  if (appState?.cartItems.length <= 0) {
    return (
      <div
        style={{
          marginTop: 100,
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={cart}
          style={{
            height: 250,
          }}
          alt="Product"
        />
        <h1 style={{ textAlign: "center" }}>Your Cart Is Empty</h1>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            router.push("/");
          }}
          style={{ height: 40, width: 200, borderRadius: 20 }}
        >
          Go Home
        </Button>
      </div>
    );
  }

  return (
    <>
      <Loader text="Loading Payment" loading={isSubmitting} />

      <SidePanel
        isVisible={showPanel}
        setIsVisible={setShowPanel}
        maxWidth={700}
      >
        <AddressForm
          uid={appState?.userDetails?.uid}
          setPanel={setShowPanel}
          userReducer={userReducer}
        />
      </SidePanel>
      <h1 style={{ textAlign: "center", fontWeight: "bold", marginTop: 100 }}>
        Your Shopping Cart
      </h1>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="flex-start"
        style={{ maxWidth: 1200, margin: "auto" }}
      >
        <Grid container item md={6} justify="center" alignItems="flex-start">
          <div
            style={
              {
                // marginTop: 150,
                // margin: "auto",
                // maxWidth: "80%",
                // height: "120vh",
                // overflowY: "scroll",
                // borderWidth: 1,
                // borderColor: "grey",
                // borderStyle: "solid",
              }
            }
          >
            {appState?.cartItems.length > 0
              ? appState?.cartItems.map((item: any) => {
                  return (
                    <CartItem
                      id={item.id}
                      key={item.id}
                      productId={item.productId}
                      image={
                        item.images[0] ??
                        "https://ih1.redbubble.net/image.1753980889.2492/st,small,507x507-pad,600x600,f8f8f8.jpg"
                      }
                      name={item.name}
                      desc={item.desc}
                      by={item.designer}
                      price={item.price}
                      quantity={item.orderedQuantity}
                      selectedSize={item.selectedSize}
                      printSide={item.printSide}
                      color={item.color}
                      size={item.size || ["5 x 5", "10 x 10", "15 x 15"]}
                      onQuantityChange={(quantity: number) => {
                        appReducer({
                          type: "update_cart_item",
                          payload: { ...item, quantity },
                        });
                      }}
                      onSizeChange={(size: string) => {
                        console.log({ ...item, size });
                        appReducer({
                          type: "update_cart_item",
                          payload: { ...item, size },
                        });
                      }}
                      onRemoveItem={() => {
                        appReducer({
                          type: "remove_cart_item",
                          payload: item,
                        });
                      }}
                    />
                  );
                })
              : null}
          </div>
        </Grid>
        <Grid container item md={6} xl={6} justify="center" alignItems="center">
          {appState?.cartItems.length > 0 ? (
            <>
              {/* <CheckoutProvider
                checkoutJsInstance={checkoutJsInstance}
                config={token ? config : null}
                openInPopup={true}
              > */}
              {/* <Button
                variant="outlined"
                color="primary"
                style={{ width: 100, height: 50 }}
                onClick={() => {
                  payWithRazorpay();
                  // setShowCheckout(true);
                }}
              >
                Show
              </Button>

              <Button
                variant="outlined"
                color="primary"
                style={{ width: 100, height: 50 }}
                // onClick={loadCheckoutScript}
              >
                Load
              </Button>
              <InjectedCheckout /> */}
              {/* {showCheckout && <Checkout />} */}
              {/* <Checkoutform
              cart_products={[]}
              promo={{ discount_amount: 100, code_name: "thr" }}
              total_amount={300}
            > */}
              <MaterialAccordion
                header={
                  appState?.userDetails?.email
                    ? "Logged In"
                    : "Please Login to continue"
                }
                containerHeight={40}
                style={{
                  maxWidth: 600,
                  width: "100%",
                  marginBottom: 10,
                  marginTop: 0,
                }}
                disabled={true}
              >
                {appState?.userDetails?.email ? (
                  <div>
                    <Typography variant="h5" gutterBottom>
                      Email : {appState?.userDetails?.email}
                    </Typography>
                  </div>
                ) : null}
              </MaterialAccordion>
              <MaterialAccordion
                header="Shipping Address"
                containerHeight={300}
                style={{
                  maxWidth: 600,
                  width: "100%",
                  marginBottom: 10,
                  marginTop: 0,
                }}
              >
                <div>
                  <Button
                    variant="outlined"
                    fullWidth
                    color="primary"
                    onClick={() => {
                      if (appState?.userDetails?.email) {
                        setShowPanel(true);
                      } else {
                        toast.error("Please login first");
                      }
                    }}
                    style={{
                      display: "block",
                      marginTop: 10,
                      height: 50,
                      width: "70%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderRadius: 25,
                    }}
                  >
                    Add Address
                  </Button>
                  {userState?.address?.map((a) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          margin: 10,
                          marginLeft: 0,
                          marginRight: 0,
                          padding: 20,
                          borderRadius: 10,
                          cursor: "pointer",
                          border:
                            a.addressId === selectedAddress?.addressId
                              ? "1px solid green"
                              : "0px",
                          boxShadow:
                            "0  0px 10px rgba(154,160,185,0.05),  0 0px 40px rgba(166,173,201,0.2)",
                        }}
                        onClick={() => setSelectedAddress(a)}
                      >
                        <div>
                          <h4 style={{ margin: 0 }}>
                            {a?.firstName} {a?.lastName}
                          </h4>
                          <p style={{ margin: 0 }}>
                            {a?.address1} {a?.address2}, {a.city}, {a.state}{" "}
                            {a.zip}
                          </p>
                          <p style={{ margin: 0 }}>Mobile: {a.mobile}</p>
                        </div>
                        <div></div>
                      </div>
                    );
                  })}
                </div>
              </MaterialAccordion>
              <MaterialAccordion
                header="Your Order Summary"
                containerHeight={160}
                style={{
                  maxWidth: 600,
                  width: "100%",
                  marginBottom: 20,
                  marginTop: 0,
                }}
                disabled={true}
              >
                <div style={{}}>
                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                  >
                    <Typography>Subtotal</Typography>
                    <Typography>Rs.{total}</Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                  >
                    <div>
                      <Typography>Delivery</Typography>
                      <Typography>4 to 5 Working Days</Typography>
                    </div>
                    <Typography>Rs.50</Typography>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 10,
                    }}
                  >
                    <Typography>Discount</Typography>
                    <Typography>Rs.0</Typography>
                  </div> */}
                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                    }}
                  >
                    <Typography>Total (GST Inc.)</Typography>
                    <Typography>RS.{total + 50}</Typography>
                  </div>
                </div>
              </MaterialAccordion>
              <div
                style={{
                  width: "100%",
                  maxWidth: 600,
                  display: "flex",
                  justifyContent: "center",
                  paddingLeft: "10%",
                  paddingRight: "10%",
                  marginBottom: 50,
                }}
              >
                {/* <GooglePayButton
                  environment="TEST"
                  buttonSizeMode="fill"
                  style={{ width: "100%" }}
                  buttonType="checkout"
                  paymentRequest={{
                    apiVersion: 2,
                    apiVersionMinor: 0,
                    allowedPaymentMethods: [
                      {
                        type: "CARD",
                        parameters: {
                          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                          allowedCardNetworks: ["MASTERCARD", "VISA"],
                        },
                        tokenizationSpecification: {
                          type: "PAYMENT_GATEWAY",
                          parameters: {
                            gateway: "example",
                            gatewayMerchantId: "exampleGatewayMerchantId",
                          },
                        },
                      },
                    ],
                    merchantInfo: {
                      merchantId: "BCR2DN6TU64M5PLN",
                      merchantName: "UrbanKlozet",
                    },
                    transactionInfo: {
                      totalPriceStatus: "FINAL",
                      totalPriceLabel: "Total",
                      totalPrice: "100.00",
                      currencyCode: "INR",
                      countryCode: "IN",
                    },
                  }}
                  onLoadPaymentData={(paymentRequest) => {
                    console.log("onload payment data", paymentRequest);
                    checkOut(paymentRequest);
                  }}
                  onCancel={(paymentRequest) => {
                    console.log("onCancel", paymentRequest);
                  }}
                  onError={(paymentRequest) => {
                    console.log("onError", paymentRequest);
                  }}
                  // onPaymentAuthorized={(paymentData) => {
                  //   console.log("onPaymentAuthorized", paymentData);
                  // }}
                /> */}
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={checkOut}
                  style={{
                    marginTop: 10,
                    height: 50,
                    borderRadius: 25,
                  }}
                >
                  Place Order
                </Button>
              </div>
              {/* </CheckoutProvider> */}
            </>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}

export default CartPage;
