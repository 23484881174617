import React, { useContext, useState } from "react";
import OrderItem from "../../components/Ecommerce/OrderItem/OrderItem";
// import { requireAdminAuth } from "../../util/auth";
import { requireAdminAuth } from "../../util/auth";
import firebase from "../../util/firebase";
// import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { UserContext } from "../../config/context/UserContext";
import { AppContext } from "../../config/context/AppContext";
import TableComponent from "../../components/Reusable/Table";
import { makeStyles } from "@material-ui/core";

const db = firebase.firestore();

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minHeight: "100vh",
      [theme.breakpoints.down("sm")]: {
        marginTop: 50,
        padding: 10,
      },
    },
  };
});

export interface ManageUsersProps {}
function ManageUsers(props: ManageUsersProps) {
  const classes = useStyles();
  const { appState, appReducer } = useContext(AppContext) as any;
  const { userState, userReducer } = useContext(UserContext) as any;

  const onRefresh = () => {};

  //Functions
  const onNext = () => {
    if (
      !appState.aUsersNoMoreDocs &&
      appState.aAllUsers[appState?.aAllUsers.length - 1]?.id ===
        appState?.aUsers[appState?.aUsers.length - 1]?.id
    ) {
      db.collection("users")
        .orderBy("createdAt", "desc")
        .startAfter(appState.aUsersLastDoc)
        .limit(10)
        .get()
        .then((res) => {
          if (res.empty) {
            appReducer({
              type: "SET_NOMORE_USERS",
              payload: true,
            });
          } else {
            const docs: any = [];
            res.docs.forEach((doc: any) => {
              docs.push({ ...doc.data(), id: doc.id });
            });
            appReducer({
              type: "SET_NEXT_USERS",
              payload: {
                users: docs,
                lastDoc: res.docs[res.docs.length - 1],
                fromDB: true,
              },
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (
      appState.aAllUsers[appState?.aAllUsers.length - 1]?.id !==
      appState?.aUsers[appState?.aUsers.length - 1]?.id
    ) {
      appReducer({
        type: "SET_NEXT_USERS",
        payload: {
          fromDB: false,
          users: [
            ...appState?.aAllUsers.slice(
              appState.aUsersPageNo * 10,
              appState.aUsersPageNo * 10 + 10
            ),
          ],
        },
      });
    } else {
      appReducer({
        type: "SET_NOMORE_USERS",
        payload: true,
      });
    }
  };

  const onPrevious = () => {
    const prevUsers = appState.aAllUsers.slice(
      (appState.aUsersPageNo - 2) * 10,
      (appState.aUsersPageNo - 2) * 10 + 10
    );
    if (prevUsers.length > 1) {
      appReducer({
        type: "SET_PREV_USERS",
        payload: { Users: prevUsers },
      });
    }
  };

  return (
    <>
      <div className={classes.root}>
        <TableComponent
          loading={appState.aUsersLoading}
          tableTitle={"All Users"}
          onNext={onNext}
          onPrevious={onPrevious}
          onRefreshClick={onRefresh}
          firebase={false}
          columns={[
            { name: "Email" },
            { name: "Purchases" },
            { name: "User Type" },
            { name: "Created At" },
            { name: "" },
          ]}
          data={appState?.aUsers}
          values={[
            { value: "email", image: false },
            { value: "purchaseCount", image: false },
            { value: "userType", image: false },
            { value: "createdAt", image: false },
          ]}
          buttonText={"View"}
          buttonLink={"/admin/user/"}
          idKey={"id"}
          Users={true}
          nextDisabled={
            appState.aUsersNoMoreDocs &&
            appState.aAllUsers[appState?.aAllUsers.length - 1]?.id ===
              appState?.aUsers[appState?.aUsers.length - 1]?.id
          }
          prevDisabled={appState?.aAllUsers[0]?.id === appState?.aUsers[0]?.id}
        />
      </div>
    </>
  );
}

// export default requireAdminAuth(ManageUsers);
export default ManageUsers;
