import React from "react";
import OrderItem from "../components/Ecommerce/OrderItem/OrderItem";
import { requireAuth } from "../util/auth";
// import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { UserContext } from "../config/context/UserContext";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minHeight: "80vh",
      padding: 100,
      [theme.breakpoints.down("sm")]: {
        marginTop: 50,
        padding: 10,
      },
    },
  };
});

export interface OrdersPageProps {}
function OrdersPage(props: OrdersPageProps) {
  const { userState, userReducer } = React.useContext(UserContext) as any;
  const classes = useStyles();
  // const state = useSelector((state: any) => state);
  return (
    <>
      <div className={classes.root}>
        <h1 style={{ textAlign: "center", marginBottom: 50 }}>My Orders</h1>
        {userState.orders.map((order: any) => (
          <OrderItem
            order={order}
            click={() => {
              userReducer({ type: "view_order_details", payload: order });
            }}
          />
        ))}
      </div>
    </>
  );
}

export default requireAuth(OrdersPage);
