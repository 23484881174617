import {
  Storefront,
  LocalMall,
  PeopleAlt,
  Brush,
  Settings,
  Tune,
  SpeakerNotes,
  PhotoFilter,
} from "@material-ui/icons";
import index from "./index";
import ManageOrders from "./Admin/ManageOrders";
import OrderDetails from "./Admin/OrderDetails";
import ManageUsers from "./Admin/ManageUsers";
import UserDetails from "./Admin/UserDetails";
import ManageDesigners from "./Admin/ManageDesigners";
import DesignerDetails from "./Admin/DesignerDetails";
import ManageProducts from "./Admin/ManageProducts";
import ProductDetails from "./Admin/ProductDetails";
import AddNewProduct from "./Admin/AddNewProduct";

var routes = [
  {
    path: "/products",
    name: "Products",
    icon: Storefront,
    iconColor: "Primary",
    component: ManageProducts,
    layout: "/admin",
    show: true,
  },
  {
    path: "/product/add",
    name: "Add Product",
    icon: PhotoFilter,
    iconColor: "Primary",
    component: AddNewProduct,
    layout: "/admin",
    show: true,
  },
  {
    path: "/product/:id",
    name: "Product Details",
    icon: Storefront,
    iconColor: "Primary",
    component: ProductDetails,
    layout: "/admin",
    show: false,
  },
  {
    path: "/orders",
    name: "Orders",
    icon: LocalMall,
    iconColor: "Primary",
    component: ManageOrders,
    layout: "/admin",
    show: true,
  },
  {
    path: "/order/:id",
    name: "Order Details",
    icon: LocalMall,
    iconColor: "Primary",
    component: OrderDetails,
    layout: "/admin",
    show: false,
  },
  {
    path: "/users",
    name: "Users",
    icon: PeopleAlt,
    iconColor: "Primary",
    component: ManageUsers,
    layout: "/admin",
    show: true,
  },
  {
    path: "/user/:id",
    name: "User Details",
    icon: PeopleAlt,
    iconColor: "Primary",
    component: UserDetails,
    layout: "/admin",
    show: false,
  },
  {
    path: "/designers",
    name: "Designers",
    icon: Brush,
    iconColor: "Primary",
    component: ManageDesigners,
    layout: "/admin",
    show: true,
  },
  {
    path: "/designer/:id",
    name: "Designer Details",
    icon: Brush,
    iconColor: "Primary",
    component: DesignerDetails,
    layout: "/admin",
    show: false,
  },
  {
    path: "/app-config",
    name: "App Config",
    icon: Tune,
    iconColor: "Primary",
    component: UserDetails,
    layout: "/admin",
    show: true,
  },
  {
    path: "/settings",
    name: "Settings",
    icon: Settings,
    iconColor: "Primary",
    component: UserDetails,
    layout: "/admin",
    show: true,
  },
  {
    path: "/tickets",
    name: "Support Tickets",
    icon: SpeakerNotes,
    iconColor: "Primary",
    component: UserDetails,
    layout: "/admin",
    show: true,
  },
  {
    path: "/tickets/:id",
    name: "Support Tickets",
    icon: SpeakerNotes,
    iconColor: "Primary",
    component: ManageUsers,
    layout: "/admin",
    show: false,
  },
];
export default routes;
