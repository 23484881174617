import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";
import firebase from "util/firebase";

// Create a root reference
var storageRef = firebase.storage().ref();

export const getUUID = async () => {
  const id = await uuidv4();
  return id;
};

//Compress Function for product images max - 100kb
export const compressProductImage = async (fileData) => {
  let compressData = fileData;
  if (compressData?.size > 100000) {
    const options = {
      maxSizeMB: 0.1,
    };
    compressData = await imageCompression(compressData, options);
  }
  return compressData;
};

//Compress Function for thumbnail images max - 10kb
export const compressThumbnailImage = async (fileData) => {
  let compressData = fileData;
  if (compressData?.size > 10000) {
    const options = {
      maxSizeMB: 0.01,
    };
    compressData = await imageCompression(compressData, options);
  }
  return compressData;
};

//get meta data of image from storage firebase
export const getMetaDataFromUrl = async (url) => {
  const fileData = await firebase.storage().refFromURL(url).getMetadata();
  return fileData;
};

//Upload Function to upload images to firebase storage
export const uploadToFirebaseStorage = async (path, fileData, fileName) => {
  var metadata = {
    contentType: "image/jpeg",
  };
  let fName = fileName ? fileName : `${new Date().getTime()}-${fileData?.name}`;
  const uploadTask = await storageRef
    .child(path + fName)
    .put(fileData, metadata);
  const downloadURL = await uploadTask.ref.getDownloadURL();
  return downloadURL;
};

export const getStatusText = (text) => {
  switch (text?.toLowerCase()) {
    case "draft":
    case "issued":
      return "Pending";
    case "overdue":
    case "cancelled":
    case "void":
      return "Cancelled";
    case "confirmed":
    case "partially_received":
    case "partially_shipped":
      return "In Process";
    case "received":
    case "closed":
    case "paid":
    case "fulfilled":
      return "Completed";
    case "processed":
      return "Processed";
    default:
      return text;
  }
};

export const renderStatusColor = (status) => {
  switch (status?.toLowerCase()) {
    // return "warning";
    case "overdue":
    case "cancelled":
    case "void":
      return "danger";
    case "approved":
    case "paid":
    case "completed":
    case "received":
    case "resolved":
    case "fulfilled":
    case "closed":
      return "success";
    case "issued":
    case "confirmed":
    case "partially received":
    case "partially_received":
    case "partially_shipped":
    case "pending":
      return "info";
    case "draft":
      return "warning";
    default:
      return "primary";
  }
};

export const renderConfirmationButtonColor = (text) => {
  switch (text) {
    case "accept":
    case "add":
    case "update":
      return "success";
    case "reject":
    case "delete":
    case "rejectAll":
      return "danger";
    default:
      return;
  }
};
