import React, { useContext, useEffect, useState } from "react";
import OrderItem from "../../components/Ecommerce/OrderItem/OrderItem";
// import { requireAdminAuth } from "../../util/auth";
import { requireAdminAuth } from "../../util/auth";
import firebase from "../../util/firebase";
// import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { UserContext } from "../../config/context/UserContext";
import { AppContext } from "../../config/context/AppContext";
import TableComponent from "../../components/Reusable/Table";

import ProductsHeader from "../../components/Headers/ProductsHeader";

import { Box, Button, Grid, makeStyles, TextField } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";

import NewProductHeader from "../../components/Headers/NewProductHeader.js";
import useUploadFile from "../../helper/UploadFile";
import { firestore } from "../../util/firebase";
import { getDoc } from "firebase/firestore";
import { useHistory } from "react-router-dom";
import {
  compressProductImage,
  compressThumbnailImage,
  uploadToFirebaseStorage,
} from "../../helper/helperMethods";

import { FaUserSlash } from "react-icons/fa";
import toast from "react-hot-toast";
import UploadFile from "components/Reusable/UploadFile";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { updateProduct } from "config/ProductQueries";

const db = firebase.firestore();

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minHeight: "100vh",
      backgroundColor: "#f8f9fe",
      [theme.breakpoints.down("sm")]: {
        marginTop: 50,
        padding: 10,
      },
    },
  };
});

export interface ProductDetailsProps {}
const animatedComponents = makeAnimated();

function ProductDetails(props: any) {
  const classes = useStyles();

  //Context
  const { appState, appReducer } = useContext(AppContext) as any;
  const { userState, userReducer } = useContext(UserContext) as any;

  //State
  const [loading, setLoading] = useState<boolean>(true);
  const [orderData, setOrderData] = useState(null);
  const [data, setData] = useState<object>({
    name: "",
    desc: "",
    price: "",
    beforePrice: "",
  }) as any;
  const [productType, setProductType] = useState({
    value: "tshirt",
    label: "T-Shirt",
  }) as any;
  const [tags, setTags] = useState([]) as any;
  const [imageUrl, setImageUrl] = useState([]) as any;
  const [productThumbnailURL, setProductThumbnailURL] = useState("") as any;
  const [featuredImage, setFeaturedImage] = useState("") as any;
  const [uploadLimit, setUploadLimit] = useState(false) as any;
  const [isSubmitting, setIsSubmitting] = useState(false) as any;
  const [showProgress, setShowProgress] = useState({
    type: "",
    status: false,
  }) as any;
  const [errors, setErrors] = useState({}) as any;
  const [showConfirmation, setShowConfirmation] = useState({
    type: "",
    visible: false,
    onButtonClickFunction: null,
  }) as any;

  //Props
  const productId = props?.match?.params?.id || props?.id;
  console.log(productId);

  useEffect(() => {
    // if (appState?.userDetails?.userType === "daMakerz") {
    getDoc(db.collection("products").doc(productId))
      .then((res: any) => {
        let val = res.data();
        let data = {
          ...val,
          id: res.id,
        };
        setOrderData(data);
        setData({
          name: val?.name,
          desc: val?.desc,
          price: val?.price,
          beforePrice: val?.beforePrice,
        });
        setImageUrl(res.data()?.images?.splice(1));
        setFeaturedImage(res.data()?.images?.splice(0, 1)[0]);
        setProductThumbnailURL(res.data()?.thumbnailURL);
        setLoading(false);
        console.log(data, "OrdersDetails***");
      })
      .catch((e) => {
        setLoading(false);
        console.log("Something went wrong please refresh the page", e);
      });
    // } else {
    //   setOrderData(null);
    //   setLoading(false);
    // }
  }, [appState?.userDetails, productId]);

  const options: any = [
    { value: "tshirt", label: "Tshirt" },
    { value: "hoodie", label: "Hoodie" },
    { value: "pullover hoodie", label: "PullOver Hoodie" },
    { value: "zipup hoodie", label: "ZipUp Hoodie" },
    { value: "tanktop", label: "Tank Top" },
  ];
  const tagOptions: any = [
    { value: "quotes", label: "Quotes" },
    { value: "happy", label: "Happy" },
    { value: "sad", label: "Sad" },
    { value: "movie", label: "Movie" },
    { value: "anime", label: "Anime" },
    { value: "animal", label: "Animal" },
    { value: "memes", label: "Memes" },
    { value: "abstract", label: "Abstract" },
      { value: "crypto", label: "Cryptocurrency" },
    { value: "bitcoin", label: "bitcoin" },
    { value: "indian", label: "Indian" },
    { value: "gym", label: "Gym" },
    { value: "series", label: "Series" },
    { value: "cartoons", label: "Cartoons" },
    { value: "cat", label: "Cat" },
    { value: "dog", label: "Dog" },
    { value: "funny", label: "Funny" },
  ];

  //File upload hook
  const {
    uploadProgress,
    url,
    error,
    uploading,
    type,
    setType,
    setFileData,
    setUrl,
  } = useUploadFile("products/");

  useEffect(() => {
    if (url != "" && type === "product") {
      if (imageUrl.length === 4) {
        setUploadLimit(true);
      }
      const data = imageUrl;
      data.push(url);
      setImageUrl(data);
      setShowProgress({ type: "", status: false });
      console.log("_________data", data);
      setUrl("");
      setType("");
    }
    if (url != "" && type === "featured") {
      setFeaturedImage(url);
      setShowProgress({ type: "", status: false });
      setUrl("");
      setType("");
    }
  }, [url]);

  const removeImage = async (index) => {
    console.log(index);
    const data = [...imageUrl];
    await data.splice(index, 1);
    console.log(data);
    await setImageUrl(data);
  };

  const removeFeaturedImage = async (index) => {
    setFeaturedImage("");
  };

  const upload = async (event) => {
    setShowProgress({ type: "product", status: true });
    let fileData = await compressProductImage(event.target.files[0]);
    setType("product");
    await setFileData(fileData);
  };

  const uploadFeatured = async (event) => {
    setShowProgress({ type: "featured", status: true });

    //Create Thumbnail
    const compressedImage = await compressThumbnailImage(event.target.files[0]);
    const thumbnailURL = await uploadToFirebaseStorage(
      "products/",
      compressedImage
    );
    setProductThumbnailURL(thumbnailURL);

    //Upload Featured Image
    let fileData = await compressProductImage(event.target.files[0]);
    setType("featured");
    await setFileData(fileData);
  };

  const onChangeData = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const validateData = (data) => {
    let errors: any = {};
    if (!data.name) {
      errors.name = "Required";
    }
    if (!data.desc) {
      errors.desc = "Required";
    }
    if (!data.price) {
      errors.price = "Required";
    }
    if (!featuredImage) {
      errors.featuredImage = "Featured Image is Required";
    }
    if (imageUrl.length < 2) {
      errors.imageUrl = "At least Two Images are Required";
    }
    return errors;
  };

  const addNewProduct = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const productData = {
      published: true,
      disabled: false,
      //Brand And Categories
      brand: "UrbanKlozet",
      thumbnailURL: productThumbnailURL,
      //   gender: "male",
      //   age: "adult",
      productType: productType?.value,
      tags: tags,
      // menu: {
      //   targetAudience: mainState?.selectedAudience,
      //   mainMenu: mainState?.selectedMenu,
      //   subMenu: mainState?.selectedSubMenu,
      //   productType: mainState?.selectedProductType,
      // },

      //Merchant Details
      status: [],
      designer: "urbanklozet",
      designerId: "urbanklozet",

      //Product Details
      name: data.name,
      qName: data?.name?.toLowerCase(),
      desc: data?.desc,
      images: featuredImage ? [featuredImage, ...imageUrl] : imageUrl,
      price: data.price,
      beforePrice: data?.beforePrice,
      ukzBarcode: "",
      madeIn: "india",
      //season: mainState.season,
      //color: mainState.color?.toLowerCase(),
      material: ["cotton"],
      style: "slim fit",

      relatedProducts: [],
      updatedAt: new Date(),
    };
    const errors = await validateData(productData);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsSubmitting(false);
    } else {
      setErrors(errors);
      updateProduct(productData, productId);
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <>
        <div className={classes.root}>
          <ProductsHeader />
          <Box
            marginTop="-8rem"
            paddingTop="3rem"
            paddingBottom="8rem"
            alignItems="center"
            display="flex"
            // className={classes.wrapperBox}
            minHeight="400px"
            position="relative"
          >
            <Grid container direction="column" alignItems="center">
              <Grid container item xs justify="center">
                <Card
                  classes={{}}
                  style={{ width: "80%", padding: 20, paddingTop: 0 }}
                  className="blur"
                >
                  <CardHeader
                    //   className={}
                    title="Loading..."
                    titleTypographyProps={{
                      component: Box,
                      marginBottom: "0 !important",
                      variant: "h4",
                      textAlign: "center",
                    }}
                  />
                </Card>
              </Grid>
            </Grid>
          </Box>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={classes.root}>
        <ProductsHeader />
        <Box
          marginTop="-8rem"
          paddingTop="3rem"
          paddingBottom="8rem"
          alignItems="center"
          display="flex"
          // className={classes.wrapperBox}
          minHeight="400px"
          position="relative"
        >
          <Grid container direction="column" alignItems="center">
            <Grid container item xs justify="center">
              <Card
                classes={{}}
                style={{ width: "80%", padding: 20, paddingTop: 0 }}
                className="blur"
              >
                <CardHeader
                  //   className={}
                  title="Product Images"
                  titleTypographyProps={{
                    component: Box,
                    marginBottom: "0 !important",
                    variant: "h4",
                    textAlign: "center",
                  }}
                />
                <div
                  style={{
                    padding: 50,
                  }}
                >
                  <h6 className="heading-small text-muted mb-4">
                    Product Images
                  </h6>
                  <UploadFile
                    placeholder={`Featured \n Image`}
                    uploadLimit={featuredImage}
                    showProgress={
                      showProgress?.type === "featured" && showProgress?.status
                    }
                    imageUrl={featuredImage ? [featuredImage] : []}
                    onChange={uploadFeatured}
                    removeImage={removeFeaturedImage}
                    uploading={type === "featured" && uploading}
                    uploadProgress={type === "featured" && uploadProgress}
                  />
                  <div style={{ display: "flex" }}>
                    {errors?.featuredImage ? (
                      <h5 style={{ color: "red" }}>{errors?.featuredImage}</h5>
                    ) : null}
                  </div>
                  <UploadFile
                    placeholder={` Product \n Image`}
                    uploadLimit={uploadLimit}
                    showProgress={
                      showProgress?.type === "product" && showProgress?.status
                    }
                    imageUrl={imageUrl}
                    onChange={upload}
                    removeImage={removeImage}
                    uploading={type === "product" && uploading}
                    uploadProgress={type === "product" && uploadProgress}
                  />
                  <div style={{ display: "flex" }}>
                    {errors?.imageUrl ? (
                      <h5 style={{ color: "red" }}>{errors?.imageUrl}</h5>
                    ) : null}
                  </div>
                  <div style={{ display: "flex" }}>
                    <h5 className="mt-2">
                      Note: Please upload the images in 800 x 800 size format
                      for better user experience.
                    </h5>
                  </div>
                </div>
              </Card>
            </Grid>
            <Card
              className="blur"
              style={{
                width: "80%",
                marginTop: 20,
                padding: 20,
                paddingTop: 0,
                backgroundColor: "#f7fafc !important",
              }}
            >
              <CardHeader
                //   className={}
                title="Product Details"
                titleTypographyProps={{
                  component: Box,
                  marginBottom: "0 !important",
                  variant: "h4",
                  textAlign: "center",
                }}
              />

              <Grid
                container
                item
                spacing={3}
                xs
                justify="center"
                style={{
                  padding: 50,
                }}
              >
                <Grid item xs={12}>
                  <TextField
                    required
                    error={errors?.name}
                    id="name"
                    name="name"
                    label="Product Name"
                    value={data?.name}
                    onChange={onChangeData}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    multiline
                    rows={4}
                    error={errors?.desc}
                    value={data?.desc}
                    id="desc"
                    name="desc"
                    label="Product Description"
                    onChange={onChangeData}
                    fullWidth
                    variant="outlined"
                    // style={{ height: 150 }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Select
                    value={productType}
                    placeholder="Select productType"
                    isSearchable
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      control: (base) => ({
                        ...base,
                        height: 80,
                        minHeight: 80,
                      }),
                    }}
                    onChange={(selectedOption: any) => {
                      console.log(selectedOption);
                      setProductType(selectedOption);
                    }}
                    options={options}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Select
                    value={tags}
                    placeholder="Select Tags"
                    isClearable
                    isSearchable
                    isMulti
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      control: (base) => ({
                        ...base,
                        height: 80,
                        minHeight: 80,
                      }),
                    }}
                    onChange={(value: any) => {
                      console.log(value);
                      setTags(value);
                    }}
                    options={tagOptions}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    value={data?.price}
                    error={errors?.price}
                    id="price"
                    name="price"
                    placeholder="Price including tax"
                    onChange={onChangeData}
                    label="Price (Rs.)"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={data?.beforePrice}
                    error={errors?.beforePrice}
                    id="beforePrice"
                    name="beforePrice"
                    placeholder="Leave empty if no offer (Should be more than price)"
                    onChange={onChangeData}
                    label="Before Price (Rs.)"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    color="primary"
                    disabled={isSubmitting}
                    onClick={addNewProduct}
                    style={{
                      marginTop: 50,
                      height: 50,
                      borderRadius: 25,
                    }}
                  >
                    Update Product
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Box>
      </div>
    </>
  );
}

// export default requireAdminAuth(ProductDetails);
export default ProductDetails;
