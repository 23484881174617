import React, { useContext, useState } from "react";
import OrderItem from "../../components/Ecommerce/OrderItem/OrderItem";
// import { requireAdminAuth } from "../../util/auth";
import { requireAdminAuth } from "../../util/auth";
import firebase from "../../util/firebase";
// import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { UserContext } from "../../config/context/UserContext";
import { AppContext } from "../../config/context/AppContext";
import TableComponent from "../../components/Reusable/Table";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import ProductCard from "../../components/Ecommerce/Product3/ProductCard";
import ProductsHeader from "../../components/Headers/ProductsHeader";
import InfiniteScroll from "react-infinite-scroll-component";

const db = firebase.firestore();

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minHeight: "100vh",
      [theme.breakpoints.down("sm")]: {
        marginTop: 50,
        padding: 10,
      },
    },
  };
});

export interface ManageProductsProps {}
function ManageProducts(props: ManageProductsProps) {
  const classes = useStyles();
  const { appState, appReducer } = useContext(AppContext) as any;
  const { userState, userReducer } = useContext(UserContext) as any;

  const onRefresh = () => {};
  const fetchMore = () => {};

  return (
    <>
      <div className={classes.root}>
        <ProductsHeader />
        <Grid
          container
          className=""
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginTop: -100,
          }}
          // spacing={3}
        >
          {appState?.products.length > 0 ? (
            appState.products.map((i) => {
              return (
                <InfiniteScroll
                  dataLength={appState?.products.length}
                  hasMore={appState?.productsLastDoc ? true : false}
                  style={{ overflow: "hidden" }}
                  next={fetchMore}
                  loader={
                    true && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flex: 1,
                        }}
                      >
                        <CircularProgress size={40} color="secondary" />
                      </div>
                    )
                  }
                >
                  <Grid
                    item
                    key={i.id}
                    // onClick={() => {
                    //   // dispatch({ type: "view_product_details", payload: i });
                    // }}
                  >
                    <Link to={{ pathname: `/admin/product/${i.id}`, state: i }}>
                      <ProductCard
                        prod={{
                          name: i?.name,
                          image: i?.images[0],
                          price: i?.price,
                          designer: i?.designer,
                          isOutOfStock: false,
                        }}
                      />
                    </Link>
                  </Grid>
                </InfiniteScroll>
              );
            })
          ) : (
            <div className="my-5">
              <h1>Loading</h1>
              {/* <Spin /> */}
            </div>
          )}
        </Grid>
      </div>
    </>
  );
}

// export default requireAdminAuth(ManageProducts);
export default ManageProducts;
