import React, { useContext, useEffect, useState } from "react";
import OrderItem from "../../components/Ecommerce/OrderItem/OrderItem";
// import { requireAdminAuth } from "../../util/auth";
import { requireAdminAuth } from "../../util/auth";
import firebase from "../../util/firebase";
// import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { UserContext } from "../../config/context/UserContext";
import { AppContext } from "../../config/context/AppContext";
import {
  Box,
  Grid,
  Chip,
  makeStyles,
  Button,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from "@material-ui/core";
import OrderDetailsHeader from "../../components/Headers/OrderDetailsHeader";
import { getDoc } from "firebase/firestore";
import { useLocation } from "react-router";
import {
  AcceptUserOrder,
  RejectUserOrder,
  shipUserOrder,
} from "../../config/OrderQueries";
import clsx from "clsx";
import Loader from "../../components/Reusable/Loader";

const db = firebase.firestore();

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      minHeight: "100vh",
      [theme.breakpoints.down("sm")]: {},
    },
    detailsBox: {
      width: "90%",
      padding: 10,
      borderRadius: 10,
      backgroundColor: "white",
      color: "black",
      boxShadow: `0 5px 10px rgba(154, 160, 185, 0.05),
      0 15px 40px rgba(166, 173, 201, 0.2)`,
      border: `1px solid rgba(0,0,0,.085)`,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    prodContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: 30,
      maxWidth: "100%",
      // height: 280px,
      // backgroundColor: "white",
      borderRadius: 10,
      boxShadow: `0 5px 10px rgba(154, 160, 185, 0.05),
      0 15px 40px rgba(166, 173, 201, 0.2)`,
      border: `1px solid rgba(0,0,0,.085)`,
      transition: `box-shadow 0.5s ease`,
      // prodContainer:hover: {
      //   box-shadow: 0 5px 10px rgba(127, 143, 207, 0.1),
      //     0 15px 40px rgba(166, 173, 201, 0.5),
      // },
    },

    prodImageContainer: {
      // backgroundColor: "white",
      marginBottom: 10,
      minHeight: 150,
      // height: 180,
      padding: 5,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 210,
      objectFit: "cover",
      // borderRadius: 10,
      // boxShadow: `0 5px 10px rgba(154, 160, 185, 0.05),
      // 0 15px 40px rgba(166, 173, 201, 0.2)`,
    },
    prodImage: {
      minHeight: 150,
      maxHeight: 350,
      maxWidth: 250,
      objectFit: "cover",
      borderRadius: 10,
    },
  };
});

export interface OrderDetailsProps {}
function OrderDetails(props: any) {
  const classes = useStyles();
  const { appState, appReducer } = useContext(AppContext) as any;
  const { userState, userReducer } = useContext(UserContext) as any;
  const [orderData, setOrderData] = useState(null) as any;
  const [activeStep, setActiveStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [trackingNo, setTrackingNo] = useState<string>("");
  const [trackingNoError, setTrackingNoError] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  //Props
  const orderId = props?.match?.params?.id || props?.id;
  console.log(orderId);

  let { state }: any = useLocation();
  console.log(state, "orderDetailsParams");

  useEffect(() => {
    if (state) {
      setOrderData(state);
      if (state.status === "Pending") {
        setActiveStep(0);
      }
      if (state.status === "InProcess") {
        setActiveStep(1);
      }
      if (state.status === "Shipped") {
        setActiveStep(2);
      }
      if (state.status === "Delivered") {
        setActiveStep(3);
      }
      setLoading(false);
    } else {
      // if (appState?.userDetails?.userType === "daMakerz") {
      getOrderData();
      // } else {
      //   setOrderData(null);
      //   setLoading(false);
      // }
    }
  }, [appState?.userDetails, orderId]);

  const getOrderData = () => {
    getDoc(db.collection("orders").doc(orderId))
      .then((res: any) => {
        let data = {
          ...res.data(),
          id: res.id,
        };
        setOrderData(data);
        if (data.status === "Pending") {
          setActiveStep(0);
        }
        if (data.status === "InProcess") {
          setActiveStep(1);
        }
        if (data.status === "Shipped") {
          setActiveStep(2);
        }
        if (data.status === "Delivered") {
          setActiveStep(3);
        }
        setLoading(false);
        console.log(data, "OrdersDetails***");
      })
      .catch((e) => {
        setLoading(false);
        console.log("Something went wrong please refresh the page", e);
      });
  };

  const renderBtns = () => {
    if (orderData?.status === "Pending") {
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={acceptOrder}
          >
            Accept
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={rejectOrder}
            style={{
              marginTop: 20,
              backgroundColor: "#f5365c",
              color: "white",
            }}
          >
            Reject
          </Button>
        </>
      );
    }
    if (orderData?.status === "InProcess") {
      return (
        <>
          <Dialog
            open={isOpen}
            onClose={() => {
              setIsOpen(false);
              setTrackingNoError(false);
            }}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Mark Order as Shipped
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please add the tracking no after shipping the product to the
                customer so he/she can track it.
              </DialogContentText>
              <TextField
                required
                error={trackingNoError}
                autoFocus
                value={trackingNo}
                onChange={(e) => {
                  setTrackingNo(e.target.value);
                }}
                margin="dense"
                id="trackingno"
                label="Shipment Tracking No"
                fullWidth
              />
              {trackingNoError ? (
                <p style={{ color: "red" }}>Please add a tracking no</p>
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setIsOpen(false);
                  setTrackingNoError(false);
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={shipOrder} color="primary">
                Shipped
              </Button>
            </DialogActions>
          </Dialog>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Shipped
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={rejectOrder}
            style={{
              marginTop: 20,
              backgroundColor: "#f5365c",
              color: "white",
            }}
          >
            Reject
          </Button>
        </>
      );
    }
  };

  const acceptOrder = async () => {
    await setIsSubmitting(true);
    await AcceptUserOrder(orderId, getOrderData);
    setIsSubmitting(false);
  };

  const rejectOrder = async () => {
    await setIsSubmitting(true);
    await RejectUserOrder(orderId, getOrderData);
    setIsSubmitting(false);
  };

  const shipOrder = async () => {
    if (!trackingNo) {
      setTrackingNoError(true);
      return;
    }

    setTrackingNoError(false);
    await setIsSubmitting(true);
    await shipUserOrder(orderId, getOrderData, trackingNo);
    setIsSubmitting(false);
  };

  const rejectItem = (item) => {};
  const downloadItem = () => {};

  if (loading) {
    return (
      <div className={classes.root}>
        <OrderDetailsHeader />
        <h1>Loading</h1>
      </div>
    );
  }

  return (
    <>
      <Loader text="Processing..." loading={isSubmitting} />
      <div className={classes.root}>
        <OrderDetailsHeader />
        <Box
          paddingTop="3rem"
          paddingBottom="8rem"
          marginTop="-20rem"
          alignItems="center"
          display="flex"
          // className={classes.wrapperBox}
          minHeight="200px"
          position="relative"
        >
          <Grid container direction="column" alignItems="center">
            <Grid container item direction="column" alignItems="center">
              <h1 style={{ textAlign: "center", color: "white" }}>
                Order Details - {orderData?.orderId}
              </h1>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                justifyContent="space-evenly"
                className={clsx(classes.detailsBox, "blur")}
                minHeight="200px"
                position="relative"
                style={{
                  borderWidth: 2,
                  borderColor:
                    orderData?.status === "Rejected"
                      ? "#f5365c"
                      : orderData?.status === "Pending"
                      ? "#ffeaa7"
                      : orderData?.status === "InProcess"
                      ? "#fdcb6e"
                      : orderData?.status === "Shipped"
                      ? "#0984e3"
                      : orderData?.status === "Delivered"
                      ? "#00b894"
                      : "white",
                }}
              >
                {orderData?.cancelled ? null : (
                  <Stepper
                    activeStep={activeStep}
                    // alternativeLabel
                    orientation="vertical"
                    style={{
                      // width: "80%",
                      backgroundColor: "transparent",
                    }}
                  >
                    {["Pending", "InProcess", "Shipped", "Delivered"].map(
                      (label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        );
                      }
                    )}
                  </Stepper>
                )}
                <div style={{}}>
                  <h3>
                    Status :{" "}
                    <Chip
                      size="small"
                      // avatar={<Avatar>M</Avatar>}
                      label={orderData?.status}
                      style={{
                        color:
                          orderData?.status === "Pending" ? "black" : "white",
                        backgroundColor:
                          orderData?.status === "Rejected"
                            ? "#f5365c"
                            : orderData?.status === "Pending"
                            ? "#ffeaa7"
                            : orderData?.status === "InProcess"
                            ? "#fdcb6e"
                            : orderData?.status === "Shipped"
                            ? "#0984e3"
                            : orderData?.status === "Delivered"
                            ? "#00b894"
                            : "white",
                      }}
                    />
                  </h3>
                  {orderData?.trackingNo ? (
                    <p>TrackingNo : {orderData?.trackingNo}</p>
                  ) : null}
                  <h3>Name : {orderData?.userData.name}</h3>
                  <p>
                    CreatedAt :
                    {moment(orderData?.createdAt?.toDate()).format(
                      "DD MMM YYYY, hh:mm A"
                    )}
                  </p>
                  <p>
                    UpdatedAt :{" "}
                    {moment(orderData?.updatedAt?.toDate()).format(
                      "DD MMM YYYY, hh:mm A"
                    )}
                  </p>
                </div>
                <div style={{}}>
                  <h3>Shipping Address</h3>
                  <p>
                    {orderData?.shippingInfo.firstName +
                      " " +
                      orderData?.shippingInfo.lastName}
                  </p>
                  <p>Mobile : {orderData?.shippingInfo.mobile}</p>
                  <p>
                    {orderData?.shippingInfo.address1 +
                      " " +
                      orderData?.shippingInfo?.address2}
                  </p>
                  <p>
                    {orderData?.shippingInfo.city +
                      " " +
                      orderData?.shippingInfo.state +
                      " " +
                      orderData?.shippingInfo?.zip}
                  </p>
                  <p>{orderData?.shippingInfo.country}</p>
                </div>
                <div style={{}}>
                  <h3>Payment Status </h3>
                  <p>Subtotal : Rs.{orderData?.bill?.subTotal}</p>
                  <p>Shipping : Rs.{orderData?.bill?.shipping}</p>
                  <p>Grand Total : Rs.{orderData?.bill?.grandTotal}</p>
                </div>
                <div
                  style={{
                    margin: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {renderBtns()}
                </div>
              </Box>
            </Grid>
            <Grid
              container
              item
              direction="column"
              alignItems="center"
              style={{ width: "100%" }}
            >
              <h1 style={{ textAlign: "center" }}>Item Details</h1>
              {orderData?.lineItems.map((item) => {
                return (
                  <Grid item xs={12} md={12} lg={12} style={{ width: "80%" }}>
                    <div className={classes.prodContainer}>
                      <img
                        src={item.product.images[0]}
                        className={classes.prodImage}
                        alt=""
                      />
                      <div
                        style={{
                          marginLeft: 30,
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <h2>{item.product.name}</h2>
                        <h3>by : {item.product.designer}</h3>
                        <h3>category : {item.product.category}</h3>
                        <h2>RS. {item.product.price}/pc</h2>
                      </div>
                      <div
                        style={{
                          marginLeft: 10,
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <h3>Color : {item.product.color}</h3>
                        <h3>Size : {item.product.selectedSize}</h3>
                        <h3>Quantity : {item.product.orderedQuantity}pc</h3>
                        <h3>Print on : {item.product?.printSide}</h3>
                      </div>
                      <div
                        style={{
                          marginLeft: 10,
                          width: "50%",
                          height: "100%",
                        }}
                      >
                        {!orderData?.shipped || !orderData?.cancelled ? (
                          <>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                            >
                              Download
                            </Button>
                            <br />
                            <br />
                            <Button
                              variant="contained"
                              size="small"
                              style={{
                                // marginLeft: 10,
                                backgroundColor: "#f5365c",
                                color: "white",
                              }}
                              onClick={() => {
                                rejectItem(item);
                              }}
                            >
                              Reject
                            </Button>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}

// export default requireAdminAuth(OrderDetails);
export default OrderDetails;
