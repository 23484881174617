import React, { useState } from "react";
import SwiperCore, {
  Navigation,
  Pagination,
  Parallax,
  Mousewheel,
  Autoplay,
  EffectCoverflow,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "./ProductImages2.scss";

export interface ProductImagesProps2 {
  images: string[];
}

function ProductImages2({ images }: ProductImagesProps2) {
  const [mainImage, setMainImage] = useState(
    images.length > 0 ? images[0] : ""
  );
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={1}
        slidesPerView={1}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 1,
          },
          900: {
            slidesPerView: 1,
          },
        }}
        // navigation
        speed={1000}
        parallax
        mousewheel
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: false,
        // }}
        centeredSlides={true}
        loop
        // direction="vertical"
        // freeMode
        // effect="coverflow"
        // coverflowEffect={{
        //   rotate: 20,
        //   stretch: 0,
        //   depth: 200,
        //   modifier: 1,
        //   slideShadows: true,
        // }}
        grabCursor
        pagination={{ clickable: true, dynamicBullets: true }}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
        style={{ maxWidth: 500, width: "100%", height: 600 }}
      >
        {images.map((i, key) => (
          <SwiperSlide key={"Slide" + key}>
            <div className="designContainer">
              <img
                src={i}
                alt=""
                style={{ objectFit: "contain", width: "100%", height: "auto" }}
              />
            </div>
          </SwiperSlide>
        ))}
        {/* <SwiperSlide key="Slide2">
          <div className="projectContainer">
            <img src={`https://picsum.photos/id/500/500/500`} />
          </div>
        </SwiperSlide>
        <SwiperSlide key="Slide3">
          <div className="projectContainer">
            <img src={`https://picsum.photos/id/666/500/500`} />
          </div>
        </SwiperSlide>
        <SwiperSlide key="Slide4">
          <div className="projectContainer">
            <img src={`https://picsum.photos/id/900/500/500`} />
          </div>
        </SwiperSlide>
        <SwiperSlide key="Slide5">
          <div className="projectContainer">
            <img src={`https://picsum.photos/id/300/500/500`} />
          </div>
        </SwiperSlide>
        <SwiperSlide key="Slide6">
          <div className="projectContainer">
            <img src={`https://picsum.photos/id/50/500/500`} />
          </div>
        </SwiperSlide> */}
      </Swiper>
    </>
  );
}

export default ProductImages2;
