import React from "react";
// import Section from "../../Section";
// import Container from "react-bootstrap/Container";
// import { Link } from "../../Link";
// import { Image } from "antd";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  prodContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 50,
    width: 240,
    // height: 280px,
    // backgroundColor: "white",
    borderRadius: 10,
    // boxShadow: `0 5px 10px rgba(154, 160, 185, 0.05),
    // 0 15px 40px rgba(166, 173, 201, 0.2)`,
    border: `1px solid rgba(0,0,0,.085)`,
    transition: `box-shadow 0.5s ease`,
    // prodContainer:hover: {
    //   box-shadow: 0 5px 10px rgba(127, 143, 207, 0.1),
    //     0 15px 40px rgba(166, 173, 201, 0.5),
    // },
  },

  prodImageContainer: {
    // backgroundColor: "white",
    marginBottom: 10,
    minHeight: 130,
    // height: 180,
    padding: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 210,
    objectFit: "cover",
    // borderRadius: 10,
    // boxShadow: `0 5px 10px rgba(154, 160, 185, 0.05),
    // 0 15px 40px rgba(166, 173, 201, 0.2)`,
  },
  prodImage: {
    minHeight: 150,
    maxHeight: 250,
    maxWidth: 230,
    objectFit: "cover",
    borderRadius: 10,
  },
}));

export interface ProductCardProps {
  prod?: {
    name: string;
    image: string;
    price: number;
    designer: string;
    isOutOfStock: boolean;
  };
  goTo?: (route: string) => null;
}

function ProductCard({ prod }: ProductCardProps) {
  const classes = useStyles();
  return (
    <>
      <div
        className={classes.prodContainer}
        style={{
          // borderBottomWidth: prod?.isOutOfStock ? 5 : 2,
          // borderColor: prod?.isOutOfStock ? "red" : "black",
          margin: 10,
        }}
      >
        <div className={classes.prodImageContainer}>
          {/* <Link
            to={{
              pathname: "/admin/newProduct",
              state: { prod: prod },
            }}
          > */}
          <img
            className={clsx(classes.prodImage, "blur")}
            alt="..."
            src={prod?.image}
          />
          {/* </Link> */}
        </div>
        <h3 style={{ textAlign: "left", margin: 0, padding: 0 }}>
          {prod?.name}
        </h3>
        <h5 style={{ textAlign: "left", margin: 0, padding: 0 }}>
          by {prod?.designer}
        </h5>
        {!prod?.isOutOfStock ? (
          <h3
            style={{
              textAlign: "left",
              margin: 0,
              padding: 0,
              marginBottom: 10,
            }}
          >
            SR {prod?.price}
          </h3>
        ) : (
          <h3
            style={{
              textAlign: "left",
              color: "red",
              margin: 0,
              padding: 0,
              marginBottom: 10,
            }}
          >
            Out Of Stock
          </h3>
        )}
      </div>
    </>
  );
}

export default ProductCard;
