import React, { useContext, useEffect, useState } from "react";

import OrderItem from "../../components/Ecommerce/OrderItem/OrderItem";
import { requireAdminAuth } from "../../util/auth";

import { UserContext } from "../../config/context/UserContext";
import { AppContext } from "../../config/context/AppContext";
import { Box, Button, Grid, makeStyles, TextField } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import { getDoc } from "firebase/firestore";

import NewProductHeader from "../../components/Headers/NewProductHeader.js";
import useUploadFile from "../../helper/UploadFile";
import { firestore } from "../../util/firebase";
import { useHistory } from "react-router-dom";
import {
  compressProductImage,
  compressThumbnailImage,
  uploadToFirebaseStorage,
} from "../../helper/helperMethods";

import { FaUserSlash } from "react-icons/fa";
import toast from "react-hot-toast";
import UploadFile from "components/Reusable/UploadFile";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { addProductToDb } from "config/ProductQueries";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minHeight: "100vh",
      backgroundColor: "#f8f9fe",
      [theme.breakpoints.down("sm")]: {
        marginTop: 50,
        padding: 10,
      },
    },
  };
});

export interface AddNewProductProps {}

const animatedComponents = makeAnimated();

const AddNewProduct = (props) => {
  const classes = useStyles();
  const { appState, appReducer } = useContext(AppContext) as any;
  const { userState, userReducer } = useContext(UserContext) as any;
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<object>({
    name: "",
    desc: "",
    price: "",
    beforePrice: "",
  }) as any;
  const [productType, setProductType] = useState({
    value: "tshirt",
    label: "T-Shirt",
  }) as any;
  const [tags, setTags] = useState([]) as any;

  //State
  const [imageUrl, setImageUrl] = useState([
    "https://firebasestorage.googleapis.com/v0/b/urban-klozet.appspot.com/o/products%2F1640229502786-deer-head-silhouette-painting-mobile-phone-wallpaper_53876-115881.png?alt=media&token=439ff210-4284-4dfe-81d3-7e3a55c82126",
    "https://firebasestorage.googleapis.com/v0/b/urban-klozet.appspot.com/o/products%2F1640229502786-deer-head-silhouette-painting-mobile-phone-wallpaper_53876-115881.png?alt=media&token=439ff210-4284-4dfe-81d3-7e3a55c82126",
  ]) as any;
  const [productThumbnailURL, setProductThumbnailURL] = useState(
    "https://firebasestorage.googleapis.com/v0/b/urban-klozet.appspot.com/o/products%2F1640229502786-deer-head-silhouette-painting-mobile-phone-wallpaper_53876-115881.png?alt=media&token=439ff210-4284-4dfe-81d3-7e3a55c82126"
  ) as any;
  const [featuredImage, setFeaturedImage] = useState(
    "https://firebasestorage.googleapis.com/v0/b/urban-klozet.appspot.com/o/products%2F1640229502786-deer-head-silhouette-painting-mobile-phone-wallpaper_53876-115881.png?alt=media&token=439ff210-4284-4dfe-81d3-7e3a55c82126"
  ) as any;
  const [uploadLimit, setUploadLimit] = useState(false) as any;
  const [isSubmitting, setIsSubmitting] = useState(false) as any;
  const [showProgress, setShowProgress] = useState({
    type: "",
    status: false,
  }) as any;
  const [errors, setErrors] = useState({}) as any;
  const [showConfirmation, setShowConfirmation] = useState({
    type: "",
    visible: false,
    onButtonClickFunction: null,
  }) as any;

  const options: any = [
    { value: "tshirt", label: "T-Shirt" },
    { value: "hoodie", label: "Hoodie" },
    { value: "pullover hoodie", label: "PullOver Hoodie" },
    { value: "zipup hoodie", label: "ZipUp Hoodie" },
    { value: "tanktop", label: "Tank Top" },
  ];
  const tagOptions: any = [
    { value: "quotes", label: "Quotes" },
    { value: "happy", label: "Happy" },
    { value: "sad", label: "Sad" },
    { value: "movie", label: "Movie" },
    { value: "anime", label: "Anime" },
    { value: "animal", label: "Animal" },
    { value: "memes", label: "Memes" },
    { value: "abstract", label: "Abstract" },
    { value: "crypto", label: "Cryptocurrency" },
    { value: "bitcoin", label: "bitcoin" },
    { value: "indian", label: "Indian" },
    { value: "gym", label: "Gym" },
    { value: "series", label: "Series" },
    { value: "cartoons", label: "Cartoons" },
    { value: "cat", label: "Cat" },
    { value: "dog", label: "Dog" },
    { value: "funny", label: "Funny" },
  ];

  //File upload hook
  const {
    uploadProgress,
    url,
    error,
    uploading,
    type,
    setType,
    setFileData,
    setUrl,
  } = useUploadFile("products/");

  useEffect(() => {
    if (url != "" && type === "product") {
      if (imageUrl.length === 4) {
        setUploadLimit(true);
      }
      const data = imageUrl;
      data.push(url);
      setImageUrl(data);
      setShowProgress({ type: "", status: false });
      console.log("_________data", data);
      setUrl("");
      setType("");
    }
    if (url != "" && type === "featured") {
      setFeaturedImage(url);
      setShowProgress({ type: "", status: false });
      setUrl("");
      setType("");
    }
  }, [url]);

  const removeImage = async (index) => {
    console.log(index);
    const data = [...imageUrl];
    await data.splice(index, 1);
    console.log(data);
    await setImageUrl(data);
  };

  const removeFeaturedImage = async (index) => {
    setFeaturedImage("");
  };

  const upload = async (event) => {
    setShowProgress({ type: "product", status: true });
    let fileData = await compressProductImage(event.target.files[0]);
    setType("product");
    await setFileData(fileData);
  };

  const uploadFeatured = async (event) => {
    setShowProgress({ type: "featured", status: true });

    //Create Thumbnail
    const compressedImage = await compressThumbnailImage(event.target.files[0]);
    const thumbnailURL = await uploadToFirebaseStorage(
      "products/",
      compressedImage
    );
    setProductThumbnailURL(thumbnailURL);

    //Upload Featured Image
    let fileData = await compressProductImage(event.target.files[0]);
    setType("featured");
    await setFileData(fileData);
  };

  const onChangeData = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const validateData = (data) => {
    let errors: any = {};
    if (!data.name) {
      errors.name = "Required";
    }
    if (!data.desc) {
      errors.desc = "Required";
    }
    if (!data.price) {
      errors.price = "Required";
    }
    if (!featuredImage) {
      errors.featuredImage = "Featured Image is Required";
    }
    if (imageUrl.length < 2) {
      errors.imageUrl = "At least Two Images are Required";
    }
    if (tags.length < 2) {
      errors.tags = "At least Two Images are Required";
    }
    // if (!data.season.seasonName) {
    //   errors.seasonName = "Required";
    // }
    // if (!data.season.year) {
    //   errors.seasonYear = "Required";
    // }
    // if (!data.menu?.targetAudience) {
    //   errors.targetAudience = "Required";
    // }
    // if (!data.menu?.mainMenu) {
    //   errors.mainMenu = "Required";
    // }
    // if (!data.menu?.subMenu) {
    //   errors.subMenu = "Required";
    // }
    return errors;
  };

  const addNewProduct = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const newTags: any = [];
    tags.map((i: any) => newTags.push(i?.value));

    const productData = {
      published: true,
      disabled: false,
      //Brand And Categories
      brand: "UrbanKlozet",
      thumbnailURL: productThumbnailURL,
      //   gender: "male",
      //   age: "adult",
      productType: productType?.value,
      tags: newTags,
      // menu: {
      //   targetAudience: mainState?.selectedAudience,
      //   mainMenu: mainState?.selectedMenu,
      //   subMenu: mainState?.selectedSubMenu,
      //   productType: mainState?.selectedProductType,
      // },

      //Merchant Details
      status: [],
      designer: "urbanklozet",
      designerId: "urbanklozet",

      //Product Details
      name: data.name,
      qName: data?.name?.toLowerCase(),
      desc: data?.desc,
      images: featuredImage ? [featuredImage, ...imageUrl] : imageUrl,
      price: data.price,
      beforePrice: data?.beforePrice,
      ukzBarcode: "",
      madeIn: "india",
      //season: mainState.season,
      //color: mainState.color?.toLowerCase(),
      material: ["cotton"],
      style: "slim fit",
      //care: "Hand wash",

      ////Shipping Details
      //shippingWeight: mainState.shippingWeight,
      //packageDimension: mainState.packageDimension,

      //Defaults
      //   zohoId: "",
      views: 0,
      likes: 0,
      shares: 0,
      purchaseCount: 0,
      isOutOfStock: false,
      relatedProducts: [],
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    const errors = await validateData(productData);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsSubmitting(false);
    } else {
      setErrors(errors);
      addProductToDb(productData);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <NewProductHeader />
        <Box
          marginTop="-8rem"
          paddingTop="3rem"
          paddingBottom="8rem"
          alignItems="center"
          display="flex"
          // className={classes.wrapperBox}
          minHeight="400px"
          position="relative"
        >
          <Grid container direction="column" alignItems="center">
            <Grid container item xs justify="center">
              <Card
                classes={{}}
                style={{ width: "80%", padding: 20, paddingTop: 0 }}
                className="blur"
              >
                <CardHeader
                  //   className={}
                  title="Product Images"
                  titleTypographyProps={{
                    component: Box,
                    marginBottom: "0 !important",
                    variant: "h4",
                    textAlign: "center",
                  }}
                />
                <div
                  style={{
                    padding: 50,
                  }}
                >
                  <h6 className="heading-small text-muted mb-4">
                    Product Images
                  </h6>
                  <UploadFile
                    placeholder={`Featured \n Image`}
                    uploadLimit={featuredImage}
                    showProgress={
                      showProgress?.type === "featured" && showProgress?.status
                    }
                    imageUrl={featuredImage ? [featuredImage] : []}
                    onChange={uploadFeatured}
                    removeImage={removeFeaturedImage}
                    uploading={type === "featured" && uploading}
                    uploadProgress={type === "featured" && uploadProgress}
                  />
                  <div style={{ display: "flex" }}>
                    {errors?.featuredImage ? (
                      <h5 style={{ color: "red" }}>{errors?.featuredImage}</h5>
                    ) : null}
                  </div>
                  <UploadFile
                    placeholder={` Product \n Image`}
                    uploadLimit={uploadLimit}
                    showProgress={
                      showProgress?.type === "product" && showProgress?.status
                    }
                    imageUrl={imageUrl}
                    onChange={upload}
                    removeImage={removeImage}
                    uploading={type === "product" && uploading}
                    uploadProgress={type === "product" && uploadProgress}
                  />
                  <div style={{ display: "flex" }}>
                    {errors?.imageUrl ? (
                      <h5 style={{ color: "red" }}>{errors?.imageUrl}</h5>
                    ) : null}
                  </div>
                  <div style={{ display: "flex" }}>
                    <h5 className="mt-2">
                      Note: Please upload the images in 800 x 800 size format
                      for better user experience.
                    </h5>
                  </div>
                </div>
              </Card>
            </Grid>
            <Card
              className="blur"
              style={{
                width: "80%",
                marginTop: 20,
                padding: 20,
                paddingTop: 0,
                backgroundColor: "#f7fafc !important",
              }}
            >
              <CardHeader
                //   className={}
                title="Product Details"
                titleTypographyProps={{
                  component: Box,
                  marginBottom: "0 !important",
                  variant: "h4",
                  textAlign: "center",
                }}
              />

              <Grid
                container
                item
                spacing={3}
                xs
                justify="center"
                style={{
                  padding: 50,
                }}
              >
                <Grid item xs={12}>
                  <TextField
                    required
                    error={errors?.name}
                    id="name"
                    name="name"
                    label="Product Name"
                    value={data?.name}
                    onChange={onChangeData}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    multiline
                    rows={4}
                    error={errors?.desc}
                    value={data?.desc}
                    id="desc"
                    name="desc"
                    label="Product Description"
                    onChange={onChangeData}
                    fullWidth
                    variant="outlined"
                    // style={{ height: 150 }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Select
                    value={productType}
                    placeholder="Select productType"
                    isSearchable
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      control: (base) => ({
                        ...base,
                        height: 80,
                        minHeight: 80,
                      }),
                    }}
                    onChange={(selectedOption: any) => {
                      console.log(selectedOption);
                      setProductType(selectedOption);
                    }}
                    options={options}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Select
                    value={tags}
                    placeholder="Select Tags"
                    isClearable
                    isSearchable
                    isMulti
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      control: (base) => ({
                        ...base,
                        height: 80,
                        minHeight: 80,
                      }),
                    }}
                    onChange={(value: any) => {
                      console.log(value);
                      setTags(value);
                    }}
                    options={tagOptions}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    value={data?.price}
                    error={errors?.price}
                    id="price"
                    name="price"
                    placeholder="Price including tax"
                    onChange={onChangeData}
                    label="Price (Rs.)"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={data?.beforePrice}
                    error={errors?.beforePrice}
                    id="beforePrice"
                    name="beforePrice"
                    placeholder="Leave empty if no offer (Should be more than price)"
                    onChange={onChangeData}
                    label="Before Price (Rs.)"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    color="primary"
                    // disabled={isSubmitting}
                    onClick={addNewProduct}
                    style={{
                      marginTop: 50,
                      height: 50,
                      borderRadius: 25,
                    }}
                  >
                    Add New Product
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default AddNewProduct;
