import firebase from "../util/firebase";
import { doc, setDoc, updateDoc, getFirestore } from "firebase/firestore";
import toast from "react-hot-toast";
const firestore = firebase.firestore();
const db = getFirestore();

//Create New Order
export const createOrder = async (
  userDetails,
  shippingInfo,
  cartItems,
  productsIds,
  designers,
  total
) => {
  var createOrder = firestore.collection("orders").doc();

  const userData = {
    uid: userDetails?.uid,
    email: userDetails?.email,
    name: shippingInfo?.firstName + shippingInfo?.lastName,
    mobile: shippingInfo?.mobile,
  };

  const shipping = 50;
  const discount = 0;

  // const tax = (total * 18) / 100;

  const bill = {
    subTotal: total,
    shipping: shipping,
    // taxAmount: tax,
    discount: discount,
    grandTotal: total + shipping,
    currency: "INR",
  };

  const data = {
    status: "Pending",
    cancelled: false,
    cancellationReason: "",
    completed: false,
    shipped: false,
    delivered: false,

    shippingInfo: shippingInfo,
    lineItems: cartItems,
    itemsCount: cartItems?.length,
    designers,
    userData: userData,
    bill: bill,

    orderId: createOrder.id,
    productsIds: productsIds,

    _type: "order",
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  console.log(data);

  createOrder
    .set(data)
    .then((res) => {
      //   dispatch({
      //       type: "clear_cart",
      //       payload: {},
      //     });
      //     dispatch({ type: "load_user_orders" });
      // dispatch({ type: "goto_page", payload: "/orders" });
      // dispatch({ type: "order_created" });
      // alert("Order Sucessfull");
      // cart_next({
      //   ...action,
      // });
    })
    .catch((err) => {
      // dispatch({ type: "order_error" });
      // cart_next({
      //     ...action,
      // });
      // console.log("Order Creation Failed", err);
    });
};

//Accept Order
export const AcceptUserOrder = (orderId, callback) => {
  updateDoc(doc(db, "orders", orderId), {
    status: "InProcess",
    updatedAt: new Date(),
  })
    .then((i) => {
      console.log("done");
      callback();
    })
    .catch((e) => {
      toast.error("Please try again later");
    });
};

//Reject Order
export const RejectUserOrder = (orderId, callback) => {
  updateDoc(doc(db, "orders", orderId), {
    status: "Rejected",
    cancelled: true,
    completed: true,
    cancellationReason: "Product Out Of Stock",
    updatedAt: new Date(),
  })
    .then((i) => {
      console.log("done");
      callback();
    })
    .catch((e) => {
      toast.error("Please try again later");
    });
};

//Mark Order Shipped with tracking no
export const shipUserOrder = (orderId, callback, trackingNo) => {
  updateDoc(doc(db, "orders", orderId), {
    status: "Shipped",
    shipped: true,
    trackingNo: trackingNo,
    updatedAt: new Date(),
  })
    .then((i) => {
      console.log("done");
      callback();
    })
    .catch((e) => {
      toast.error("Please try again later");
    });
};
