import React, { useEffect, useMemo } from "react";
import firebase from "../../util/firebase";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  setLocalStorageItem,
  loadLocalStorageItem,
  reomveLocalStorageItem,
} from "../Hooks/localstorage";
import { Unsubscribe } from "@material-ui/icons";
import { useAuth } from "../../util/auth";

const firestore = firebase.firestore();

export const UserContext = React.createContext({});

const initialValue = {
  orders: [],
  address: [],
  getAddress: false,
  getOrders: false,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === "SET_ORDERS") {
    return {
      ...state,
      orders: [...action.payload],
      loading: false,
    };
  }
  if (action.type === "GET_ADDRESS") {
    return {
      ...state,
      getAddress: !state.getAddress,
    };
  }
  if (action.type === "GET_ORDERS") {
    return {
      ...state,
      getOrders: !state.getOrders,
    };
  }
  if (action.type === "SET_ADDRESS") {
    return {
      ...state,
      address: action.payload,
      loading: false,
    };
  }
  return state;
};

const UserContextProvider = (props) => {
  const [userState, userReducer] = React.useReducer(reducer, initialValue);
  //   let history = useHistory();
  //   let location = useLocation();

  //Momoize State
  const contextValue = useMemo(() => {
    return { userState, userReducer };
  }, [userState, userReducer]);

  const auth = useAuth();

  useEffect(() => {
    if (auth.user) {
      const orders = [];
      firestore
        .collection("orders")
        .where("userData.uid", "==", auth?.user?.uid)
        .orderBy("createdAt", "desc")
        .limit(10)
        .get()
        .then((doc) => {
          doc.forEach((i) => {
            let doc = i.data();
            doc.id = i.id;
            orders.push(doc);
          });
          userReducer({ type: "SET_ORDERS", payload: orders });
          console.log(orders, "___orders___");
        })
        .catch((e) => {
          console.log(e, "order error");
        });
    } else {
      userReducer({ type: "SET_ORDERS", payload: [] });
    }

    return () => {};
  }, [auth, userState?.getOrders]);

  useEffect(() => {
    if (auth.user) {
      firestore
        .collection("users")
        .doc(auth?.user?.uid)
        .collection("private")
        .doc("shippingAddress")
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data();
            userReducer({
              type: "SET_ADDRESS",
              payload: data.addresses,
            });
            console.log(data.addresses, "___address___");
          }
        })
        .catch((e) => {
          console.log(e, "order error");
        });
    } else {
      userReducer({
        type: "SET_ADDRESS",
        payload: [],
      });
    }
    return () => {};
  }, [userState?.getAddress, auth]);

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
