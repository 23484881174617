import React from "react";
// import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import ClientsSection from "./../components/ClientsSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import CtaSection from "./../components/CtaSection";
import HeroSection from "./../components/Home/Hero/Hero";
import { AppContext } from "../config/context/AppContext";
import { Button, Grid } from "@material-ui/core";
import ProductCard from "../components/Ecommerce/Product3/ProductCard";
import { Link } from "./../util/router.js";

function IndexPage(props) {
  //Context
  const { appState, appReducer } = React.useContext(AppContext);
  return (
    <>
      <HeroSection />
      <h1 style={{ textAlign: "center", margin: 50 }}>Our Featured Designs</h1>
      <Grid
        container
        className=""
        style={{
          width: "80%",
          marginLeft: "10%",
          justifyContent: "center",
          alignItems: "center",
        }}
        // spacing={3}
      >
        {appState?.products.length > 0 ? (
          appState.products.map((i) => {
            return (
              <Grid
                item
                key={i.id}
                // onClick={() => {
                //   // dispatch({ type: "view_product_details", payload: i });
                // }}
              >
                <Link to={{ pathname: `/design/${i.id}`, state: i }}>
                  <ProductCard
                    prod={{
                      name: i?.name,
                      image: i?.images[0],
                      price: i?.price,
                      designer: i?.designer,
                      isOutOfStock: false,
                    }}
                  />
                </Link>
              </Grid>
            );
          })
        ) : (
          <div className="my-5">
            <h1>Loading</h1>
            {/* <Spin /> */}
          </div>
        )}
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          // dispatch({
          //   type: "goto_page",
          //   payload: "/products",
          // });
        }}
        style={{
          display: "block",
          margin: "auto",
          marginBottom: 50,
          marginTop: 50,
          height: 50,
          width: 200,
          borderRadius: 25,
        }}
      >
        View More
      </Button>
      {/* <HeroSection
        bgColor="primary"
        size="large"
        bgImage="https://source.unsplash.com/ugnrXk1129g/1600x800"
        bgImageOpacity={0.3}
        title="Your landing page title here"
        subtitle="This landing page is perfect for showing off your awesome product and driving people to sign up for a paid plan."
        buttonText="Get Started"
        buttonColor="default"
        buttonPath="/pricing"
      /> */}
      {/* <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="All the features you need to move faster"
      /> */}
      <ClientsSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Featured Designers"
        subtitle="Get Featured every month"
      />
      <TestimonialsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      />
      <NewsletterSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="default"
        buttonPath="/"
      />
    </>
  );
}

export default IndexPage;
