import React from "react";
import axios from "axios";

export const getRazorpayInstance = (total) => {
  console.log("started");
  return axios({
    method: "post",
    url: "https://us-central1-fitting-room-3d548.cloudfunctions.net/checkoutHelperRP",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    data: {
      total: total * 100,
      //   orderId,
      //   test: false,
    },
  })
    .then((res) => {
      //   setToken(res.data.body);
      console.log(res.data, "*********RPInstanceId");
      return res.data;
    })
    .catch((e) => console.log(e));
};

export const loadScript = async (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    script.type = "application/javascript";
    script.onload = async () => {
      resolve(true);
    };
    script.onerror = async () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export const payWithRazorpay = async (total, setIsSubmitting, success) => {
  console.log("Loading script");
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
  if (!res) {
    console.log("Razorpay failed to load");
    return;
  }

  const data: any = await getRazorpayInstance(total);
  console.log("loadeddd", data);
  var options = {
    key: "rzp_test_NpQfB3MVYqeqGR", // Enter the Key ID generated from the Dashboard
    amount: data?.amount.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    order_id: data?.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    currency: "INR",
    name: "UrbanKlozet",
    description: "Test Transaction",
    handler: function (response) {
      //   alert(response.razorpay_payment_id);
      console.log(response);
      alert("Transaction Sucessfull with id " + response.razorpay_order_id);
      if (response.razorpay_order_id) {
        success();
      }
      //   alert(response.razorpay_signature);
    },
    prefill: {
      //   name: "Gaurav Kumar",
      //   email: "gaurav.kumar@example.com",
      //   contact: "9999999999",
    },
    // notes: {
    //   address: "Razorpay Corporate Office",
    // },
    // theme: {
    //   color: "#3399cc",
    // },
  };

  const _window: any = window;

  if (_window.Razorpay && data.amount) {
    const pmtObj = new _window.Razorpay(options);
    setIsSubmitting(false);
    pmtObj.open();
    pmtObj.on("close", function (response) {
      console.log("dialogue closed");
    });
  }

  return;
};
