import React from "react";
import "./Hero.scss";
import SwiperCore, {
  Navigation,
  Pagination,
  Parallax,
  Mousewheel,
  Autoplay,
  EffectCoverflow,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// SwiperCore.use([
//   Navigation,
//   Pagination,
//   Parallax,
//   Mousewheel,
//   Autoplay,
//   EffectCoverflow,
// ]);

// export interface HeroSectionProps {}

function HeroSection() {
  return (
    <>
      <div className="root">
        <Swiper
          modules={[
            Navigation,
            Pagination,
            Parallax,
            Mousewheel,
            Autoplay,
            EffectCoverflow,
          ]}
          navigation
          parallax
          speed={1000}
          loop
          // autoplay={{
          //   delay: 5500,
          //   disableOnInteraction: false,
          // }}
          grabCursor
          pagination={{ clickable: true, dynamicBullets: true }}
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
          onProgress={(swiper) => {
            var interleaveOffset = 0.5;
            for (var i = 0; i < swiper.slides.length; i++) {
              var slideProgress = swiper.slides[i].progress;
              var innerOffset = swiper.width * interleaveOffset;
              var innerTranslate = slideProgress * innerOffset;
              swiper.slides[i].querySelector("#slide").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
            }
          }}
          onTouchStart={(swiper) => {
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = "";
            }
          }}
          onSetTransition={(swiper, transition) => {
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = transition + "ms";
              swiper.slides[i].querySelector("#slide").style.transition =
                transition + "ms";
            }
          }}
          style={{ width: "100%", height: "100%" }}
        >
          <SwiperSlide key="Slide1" style={{ overflow: "hidden" }}>
            <div
              className={"projectContainer"}
              id="slide"
              style={{
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1593726852644-36ad40fd4b66?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1500&q=80)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <h1 data-swiper-parallax="300">Limited Edition</h1>
              <br />
              <h1 data-swiper-parallax="300">World Class Quality</h1>
            </div>
          </SwiperSlide>
          <SwiperSlide key="Slide2" style={{ overflow: "hidden" }}>
            <div
              className={"projectContainer"}
              id="slide"
              style={{
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1503341778367-b05998a89218?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1500&q=80)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></div>
          </SwiperSlide>
          <SwiperSlide key="Slide3" style={{ overflow: "hidden" }}>
            <div
              className={"projectContainer"}
              id="slide"
              style={{
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1512401035442-e40d840cfdd0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1500&q=80)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}

export default HeroSection;
