import firebase from "../util/firebase";
import { v4 as uuid } from "uuid";

const firestore = firebase.firestore();

export const createAddress = async (address, uid) => {
  //TODO: Edit Address use batch write

  console.log(address, uid);
  var createAddress = firestore
    .collection("users")
    .doc(uid)
    .collection("private")
    .doc("shippingAddress");

  const newAddress = [
    {
      ...address,
      uid: uid,
      _type: "address",
      createdAt: new Date(),
      updatedAt: new Date(),
      addressId: uuid(),
    },
  ];

  const data = {
    addresses: newAddress,
    uid: uid,
    _type: "addresses",
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  console.log(data);
  createAddress
    .set(data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return "err";
    });
};
