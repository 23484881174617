import React, { useContext, useState } from "react";
import OrderItem from "../../components/Ecommerce/OrderItem/OrderItem";
// import { requireAdminAuth } from "../../util/auth";
import { requireAdminAuth } from "../../util/auth";
import firebase from "../../util/firebase";
// import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { UserContext } from "../../config/context/UserContext";
import { AppContext } from "../../config/context/AppContext";
import TableComponent from "../../components/Reusable/Table";
import { makeStyles } from "@material-ui/core";

const db = firebase.firestore();

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minHeight: "100vh",
      [theme.breakpoints.down("sm")]: {
        marginTop: 50,
        padding: 10,
      },
    },
  };
});

export interface ManageOrdersProps {}
function ManageOrders(props: ManageOrdersProps) {
  const classes = useStyles();
  const { appState, appReducer } = useContext(AppContext) as any;
  const { userState, userReducer } = useContext(UserContext) as any;

  const onRefresh = () => {};

  //Functions
  const onNext = () => {
    if (
      !appState.aOrdersNoMoreDocs &&
      appState.aAllOrders[appState?.aAllOrders.length - 1]?.id ===
        appState?.aOrders[appState?.aOrders.length - 1]?.id
    ) {
      db.collection("orders")
        .orderBy("createdAt", "desc")
        .startAfter(appState.aOrdersLastDoc)
        .limit(10)
        .get()
        .then((res) => {
          if (res.empty) {
            appReducer({
              type: "SET_NOMORE_ORDERS",
              payload: true,
            });
          } else {
            const docs: any = [];
            res.docs.forEach((doc: any) => {
              docs.push({ ...doc.data(), id: doc.id });
            });
            appReducer({
              type: "SET_NEXT_ORDERS",
              payload: {
                orders: docs,
                lastDoc: res.docs[res.docs.length - 1],
                fromDB: true,
              },
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (
      appState.aAllOrders[appState?.aAllOrders.length - 1]?.id !==
      appState?.aOrders[appState?.aOrders.length - 1]?.id
    ) {
      appReducer({
        type: "SET_NEXT_ORDERS",
        payload: {
          fromDB: false,
          orders: [
            ...appState?.aAllOrders.slice(
              appState.aOrdersPageNo * 10,
              appState.aOrdersPageNo * 10 + 10
            ),
          ],
        },
      });
    } else {
      appReducer({
        type: "SET_NOMORE_ORDERS",
        payload: true,
      });
    }
  };

  const onPrevious = () => {
    const prevOrders = appState.aAllOrders.slice(
      (appState.aOrdersPageNo - 2) * 10,
      (appState.aOrdersPageNo - 2) * 10 + 10
    );
    if (prevOrders.length > 1) {
      appReducer({
        type: "SET_PREV_ORDERS",
        payload: { orders: prevOrders },
      });
    }
  };

  return (
    <>
      <div className={classes.root}>
        <TableComponent
          loading={appState.aOrdersloading}
          tableTitle={"Sales Orders"}
          onNext={onNext}
          onPrevious={onPrevious}
          onRefreshClick={onRefresh}
          firebase={false}
          columns={[
            { name: "Order Number" },
            { name: "Status" },
            { name: "Customer Name" },
            { name: "Products" },
            { name: "Created At" },
            { name: "Grand Total" },
            { name: "" },
          ]}
          data={appState?.aOrders}
          values={[
            { value: "id", image: false },
            { value: "status", image: false },
            { value: "userData", image: false },
            { value: "lineItems", image: true },
            { value: "createdAt", image: false },
            { value: "bill", image: false },
          ]}
          buttonText={"View"}
          buttonLink={"/admin/order/"}
          idKey={"id"}
          orders={true}
          nextDisabled={
            appState.aOrdersNoMoreDocs &&
            appState.aAllOrders[appState?.aAllOrders.length - 1]?.id ===
              appState?.aOrders[appState?.aOrders.length - 1]?.id
          }
          prevDisabled={
            appState?.aAllOrders[0]?.id === appState?.aOrders[0]?.id
          }
        />
      </div>
    </>
  );
}

// export default requireAdminAuth(ManageOrders);
export default ManageOrders;
