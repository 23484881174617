import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function MaterialAccordion(props) {
  const [open, setOpen] = useState(true);

  const toggleOpen = () => {
    if (props.disabled) return;
    setOpen(!open);
  };
  return (
    <>
      <div
        style={{
          padding: 25,
          paddingTop: 15,
          paddingBottom: 15,
          margin: 10,
          marginBottom: 30,
          borderRadius: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow:
            "0  0px 10px rgba(154,160,185,0.05),  0 0px 40px rgba(166,173,201,0.2)",
          overflow: "hidden",
          ...props.style,
        }}
      >
        <div
          onClick={toggleOpen}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            {props?.header}
          </Typography>
          {props?.disabled ? null : (
            <IconButton>
              <ExpandMoreIcon
                style={{
                  transform: open ? `rotate(180deg)` : `rotate(0deg)`,
                  transition: "all 0.35s ease",
                }}
              />
            </IconButton>
          )}
        </div>
        <div
          style={{
            height: open ? props.containerHeight : 0,
            opacity: open ? 1 : 0,
            transition: "all 0.35s ease",
            width: "100%",
            // overflowY: "scroll",
            borderRadius: 8,
            // backgroundColor: "#f7f7fc",
          }}
        >
          {props.children}
        </div>
        {/* {open ? (
          <div
            style={{
              width: "100%",
              paddingTop: 10,
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h4>Footer</h4>
          </div>
        ) : null} */}
      </div>
    </>
  );
}
