import React, { useEffect, useMemo } from "react";
import firebase from "../../util/firebase";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  setLocalStorageItem,
  loadLocalStorageItem,
  reomveLocalStorageItem,
} from "../Hooks/localstorage";
import { useAuth } from "../../util/auth";
import { getDocs, onSnapshot } from "firebase/firestore";

const firestore = firebase.firestore();

export const AppContext = React.createContext({});

const initialValue = {
  user: null,
  userDetails: null,
  loggedIn: false,
  products: [],
  productsLastDoc: null,
  config: [],
  cartItems: [],
  orderedItems: [],
  total: 0,
  resetData: true,

  //AdminManageOrders
  aOrders: [],
  aAllOrders: [],
  aOrdersPageNo: 1,
  aOrdersLastDoc: null,
  aOrdersLoading: true,
  aOrdersNoMoreDocs: false,
  aOrdersNoMoreNext: false,

  //AdminManageUsers
  aUsers: [],
  aAllUsers: [],
  aUserPageNo: 1,
  aUsersLastDoc: null,
  aUsersLoading: true,
  aUsersNoMoreDocs: false,
  aUsersNoMoreNext: false,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === "SET_INITIAL_PRODUCT") {
    return {
      ...state,
      products: [...action.payload],
      loading: false,
    };
  }
  if (action.type === "SET_PRODUCT_LASTDOC") {
    return {
      ...state,
      productsLastDoc: action.payload,
    };
  }

  if (action.type === "SET_CONFIG") {
    return {
      ...state,
      config: [...action.payload],
      loading: false,
    };
  }

  if (action.type === "SET_INIT_USER") {
    return {
      ...state,
      userDetails: action.payload,
      loading: false,
    };
  }

  //Add Item to cart
  if (action.type === "init_cart") {
    return {
      ...state,
      cartItems: action.payload,
    };
  }

  //Add Item to cart
  if (action.type === "add_item") {
    const { item } = action.payload;
    const newItem = { ...item, id: uuid() };
    setLocalStorageItem("cartItems", [...state.cartItems, newItem]);
    return {
      ...state,
      cartItems: [...state.cartItems, newItem],
    };
  }

  //Remove CartItem
  if (action.type === "remove_cart_item") {
    const item = action.payload;
    const data = [...state.cartItems];
    const cartItems = data.filter((i) => i.id !== item.id);
    setLocalStorageItem("cartItems", [...cartItems]);
    return {
      ...state,
      cartItems: [...cartItems],
    };
  }

  //Update CartItem
  if (action.type === "update_cart_item") {
    const item = action.payload;
    const cartItems = state.cartItems.map((i) => (i.id === item.id ? item : i));
    setLocalStorageItem("cartItems", [...cartItems]);
    return {
      ...state,
      cartItems: [...cartItems],
    };
  }

  //Clear Cart
  if (action.type === "SET_ORDER_ITEMS") {
    setLocalStorageItem("cartItems", []);
    const data = state.cartItems.slice();
    return {
      ...state,
      orderedItems: data,
      cartItems: [],
      total: 0,
    };
  }

  //Clear Cart
  if (action.type === "clear_cart") {
    setLocalStorageItem("cartItems", []);
    return {
      ...state,
      cartItems: [],
      total: 0,
    };
  }

  //Set Orders For Admin
  if (action.type === "SET_ADMIN_ORDERS") {
    // setLocalStorageItem("cartItems", []);
    return {
      ...state,
      aOrders: action.payload.orders,
      aAllOrders: action.payload.orders,
      aOrdersLastDoc: action.payload.lastDoc,
      aOrdersLoading: false,
    };
  }
  //Set Orders For Admin
  if (action.type === "SET_NEXT_ORDERS") {
    if (action.payload.fromDB) {
      return {
        ...state,
        aOrders: action.payload.orders,
        aAllOrders: [...state.aAllOrders, ...action.payload.orders],
        aOrdersLastDoc: action.payload.lastDoc,
        aOrdersPageNo: state.aOrdersPageNo + 1,
        aOrdersNoMoreDocs: action.payload.orders.length < 10 ? true : false,
      };
    } else {
      return {
        ...state,
        aOrders: action.payload.orders,
        aOrdersPageNo: state.aOrdersPageNo + 1,
        aOrdersNoMoreNext: action.payload.orders.length < 10 ? true : false,
      };
    }
  }
  //Set Orders For Admin
  if (action.type === "SET_PREV_ORDERS") {
    // setLocalStorageItem("cartItems", []);
    return {
      ...state,
      aOrders: action.payload.orders,
      aOrdersNoMoreNext: false,
      aOrdersPageNo: state.aOrdersPageNo - 1,
    };
  }
  //Set Orders For Admin
  if (action.type === "SET_NOMORE_ORDERS") {
    // setLocalStorageItem("cartItems", []);
    return {
      ...state,
      aOrdersNoMoreDocs: action.payload,
    };
  }
  //Set Orders For Admin
  if (action.type === "SET_NONEXT_ORDERS") {
    // setLocalStorageItem("cartItems", []);
    return {
      ...state,
      aOrdersNoMoreNext: action.payload,
    };
  }
  //Set Users For Admin
  if (action.type === "SET_ADMIN_USERS") {
    // setLocalStorageItem("cartItems", []);
    return {
      ...state,
      aUsers: action.payload.users,
      aAllUsers: action.payload.users,
      aUsersLastDoc: action.payload.lastDoc,
      aUsersNoMoreDocs: action.payload.users.length < 10 ? true : false,
      aUsersLoading: false,
    };
  }

  //Set Users For Admin
  if (action.type === "SET_NEXT_USERS") {
    if (action.payload.fromDB) {
      return {
        ...state,
        aUsers: action.payload.users,
        aAllUsers: [...state.aAllUsers, ...action.payload.users],
        aUsersLastDoc: action.payload.lastDoc,
        aUsersPageNo: state.aUsersPageNo + 1,
        aUsersNoMoreDocs: action.payload.users.length < 10 ? true : false,
      };
    } else {
      return {
        ...state,
        aUsers: action.payload.users,
        aUsersPageNo: state.aUsersPageNo + 1,
        aUsersNoMoreNext: action.payload.users.length < 10 ? true : false,
      };
    }
  }
  //Set Users For Admin
  if (action.type === "SET_PREV_USERS") {
    // setLocalStorageItem("cartItems", []);
    return {
      ...state,
      aUsers: action.payload.users,
      aUsersNoMoreNext: false,
      aUsersPageNo: state.aUsersPageNo - 1,
    };
  }
  //Set Users For Admin
  if (action.type === "SET_NOMORE_USERS") {
    // setLocalStorageItem("cartItems", []);
    return {
      ...state,
      aUsersNoMoreDocs: action.payload,
    };
  }
  //Set Users For Admin
  if (action.type === "SET_NONEXT_USERS") {
    // setLocalStorageItem("cartItems", []);
    return {
      ...state,
      aUsersNoMoreNext: action.payload,
    };
  }
  return state;
};

const AppContextProvider = (props) => {
  const [appState, appReducer] = React.useReducer(reducer, initialValue);

  const contextValue = useMemo(() => {
    return { appState, appReducer };
  }, [appState, appReducer]);

  // console.log(appState);
  let history = useHistory();
  const auth = useAuth();
  // console.log(auth, "appcontext");
  // let location = useLocation();

  //   useEffect(() => {
  //     let paths = location.pathname.split("/");
  //     var email = localStorage.getItem("email");
  //     var profileData = localStorage.getItem("profile");
  //     console.log(email);
  //     console.log(location);
  //     console.log(paths);
  //     if (email) {
  //       let profile = JSON.parse(profileData);
  //       appReducer({
  //         type: "SET_LOGGEDIN_TRUE",
  //         payload: { email, profile },
  //       });
  //       if (paths[1] === "") {
  //         profile.userType === "admin"
  //           ? history.push("/admin")
  //           : history.push("/merchant");
  //       } else if (paths[1] === "admin") {
  //         profile.userType === "admin"
  //           ? history.push(location.pathname)
  //           : history.push("/merchant");
  //       } else if (paths[1] === "merchant") {
  //         profile.userType === "merchant"
  //           ? history.push(location.pathname)
  //           : history.push("/admin");
  //       } else {
  //         profile.userType === "admin"
  //           ? history.push("/admin")
  //           : history.push("/merchant");
  //       }
  //     } else {
  //       history.push("/auth/login");
  //     }
  //   }, []);

  useEffect(() => {
    // let paths = location.pathname.split("/");
    const getData = async () => {
      const user = await loadLocalStorageItem("user");
      console.log(user, "user");
      appReducer({
        type: "SET_INIT_USER",
        payload: user,
      });
    };

    getData();
  }, [auth]);

  useEffect(() => {
    // if (mainState?.profile?.userType === "admin") {}
    getDocs(firestore.collection("products"))
      .then((res) => {
        const products = [];
        res.docs.forEach((product) =>
          products.push({
            ...product.data(),
            id: product.id,
          })
        );
        console.log(products);
        appReducer({
          type: "SET_INITIAL_PRODUCT",
          payload: products,
        });
        appReducer({
          type: "SET_PRODUCT_LASTDOC",
          payload: res.docs[res.docs.length - 1],
        });
      })
      .catch((e) => {
        console.log("Something went wrong please refresh the page", e);
      });

    onSnapshot(firestore.collection("config"), (snapshot) => {
      const config = [];
      snapshot.forEach((i) => {
        let d = i.data();
        d.id = i.id;
        config.push(d);
      });
      appReducer({
        type: "SET_CONFIG",
        payload: config,
      });
      console.log(config, "_configData");
    });

    const loadCart = async () => {
      const cart = await loadLocalStorageItem("cartItems");
      if (cart) {
        console.log(cart, "lsCart");
        appReducer({ type: "init_cart", payload: cart });
      }
    };
    loadCart();

    return () => {};
  }, [appState?.resetData]);

  useEffect(() => {
    if (auth?.user?.userType === "daMakerz") {
      getDocs(
        firestore.collection("orders").orderBy("createdAt", "desc").limit(10)
      )
        .then((res) => {
          const orders = [];
          res.docs.forEach((order) =>
            orders.push({
              ...order.data(),
              id: order.id,
            })
          );
          console.log(orders, "aOrders***");
          appReducer({
            type: "SET_ADMIN_ORDERS",
            payload: { orders, lastDoc: res.docs[res.docs.length - 1] },
          });
        })
        .catch((e) => {
          console.log("Something went wrong please refresh the page", e);
        });

      getDocs(
        firestore.collection("users").orderBy("createdAt", "desc").limit(10)
      )
        .then((res) => {
          const users = [];
          res.docs.forEach((user) =>
            users.push({
              ...user.data(),
              id: user.id,
            })
          );
          console.log(users, "aUsers***");
          appReducer({
            type: "SET_ADMIN_USERS",
            payload: { users, lastDoc: res.docs[res.docs.length - 1] },
          });
        })
        .catch((e) => {
          console.log("Something went wrong please refresh the page", e);
        });
    }
    return () => {};
  }, [auth]);

  return (
    <AppContext.Provider value={contextValue}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
