import React, { useState } from "react";
import { Link } from "react-router-dom";
import SwiperCore, {
  Navigation,
  Pagination,
  Parallax,
  Mousewheel,
  Autoplay,
  EffectCoverflow,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import ProductCard from "../Product3/ProductCard";
import "./ProductCarousel.scss";

export interface ProductImagesProps2 {
  products?: [];
}

function ProductCarousel({ products }: ProductImagesProps2) {
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={1}
        slidesPerView={1}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 3,
          },
          900: {
            slidesPerView: 4,
          },
        }}
        // navigation
        // speed={1000}
        // parallax
        // mousewheel
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: false,
        // }}
        // centeredSlides={true}
        // loop
        // direction="vertical"
        // freeMode
        // effect="coverflow"
        // coverflowEffect={{
        //   rotate: 20,
        //   stretch: 0,
        //   depth: 200,
        //   modifier: 1,
        //   slideShadows: true,
        // }}
        grabCursor
        pagination={{ clickable: true, dynamicBullets: true }}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
        style={{ maxWidth: 1200, width: "100%", height: 400 }}
      >
        {products?.map((i: any, key) => (
          <SwiperSlide key={"Slide" + key}>
            <Link to={{ pathname: `/design/${i?.id}`, state: i }}>
              <ProductCard
                prod={{
                  name: i?.name,
                  image: i?.images[0],
                  price: i?.price,
                  designer: i?.designer,
                  isOutOfStock: false,
                }}
              />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default ProductCarousel;
