import firebase from "../util/firebase";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getFirestore,
} from "firebase/firestore";
import toast from "react-hot-toast";
import { history } from "util/router";

const firestore = firebase.firestore();
const db = getFirestore();

export const getProductDetails = (id, cb) => {
  firestore
    .collection("products")
    .doc(id)
    .get()
    .then((doc) => {
      if (doc.exists) {
        console.log(doc.data(), "getProductDetails");
        cb(
          {
            ...doc.data(),
            id: doc.id,
          },
          "success"
        );
      } else {
        cb({}, "nodoc");
      }
    })
    .catch((e) => {
      cb(e, "error");
      console.log("getProductDetails", e);
    });
};

export const updateProductView = (id, cb) => {
  firestore
    .collection("products")
    .doc(id)
    .update({
      views: firebase.firestore.FieldValue.increment(1),
    })
    .then((doc) => {
      if (cb) {
        cb(doc, "success");
      }
    })
    .catch((e) => {
      cb(e, "error");
      console.log("getProductDetails", e);
    });
};

export const addProductToDb = (data, cb) => {
  addDoc(collection(db, "products"), data)
    .then((doc) => {
      if (cb) {
        cb(doc, "success");
      }
      toast.success("Product added successfully");
      history.push(`/admin/product/${doc.id}`);
    })
    .catch((e) => {
      toast.error("Error Adding Product");
      console.log("getProductDetails", e);
    });
};

export const updateProduct = (data, docId, cb) => {
  updateDoc(doc(db, "products", docId), data)
    .then((doc) => {
      if (cb) {
        cb(doc, "success");
      }
      toast.success("Product updated successfully");
    })
    .catch((e) => {
      toast.error("Error Adding Product");
      console.log("getProductDetails", e);
    });
};
