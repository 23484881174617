import React from "react";
// import Section from "../../Section";
// import { Link } from "../../Link";
// import { Select } from "antd";
import "./CartItem.scss";
import { Container, Col, Row } from "react-bootstrap";
import { DeleteOutlined } from "@ant-design/icons";
import config from "../../../config/config";
import { Grid, Hidden, IconButton, Select } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";

export interface CartItemProps {
  productId: string;
  name: string;
  desc?: string;
  image?: string;
  by?: string;
  price: number;
  quantity: number;
  selectedSize?: any;
  printSide?: any;
  color?: any;
  size: string[];
  id: string;
  onQuantityChange: (quantity: number) => void;
  onSizeChange: (size: string) => void;
  onRemoveItem: (id: string) => void;
}
// const { Option } = Select;

function CartItem({
  name,
  desc,
  image,
  by,
  productId,
  price,
  quantity,
  color,
  selectedSize,
  printSide,
  size,
  id,
  onQuantityChange,
  onSizeChange,
  onRemoveItem,
}: CartItemProps) {
  return (
    <>
      {/* <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      > */}
      <div className="cartItemContainer">
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
          className="mainContent"
        >
          <Grid
            container
            item
            direction="row"
            justify="center"
            alignItems="center"
          >
            <div
              style={{ position: "relative" }}
              className="thumbnailContainer"
            >
              <img src={image} alt="Product" className="cartItemThumbnail" />
              <div className="closeSM">
                <IconButton
                  aria-label="delete"
                  style={{
                    position: "absolute",
                    bottom: -15,
                    left: -25,
                  }}
                >
                  <DeleteOutlined
                    style={{
                      // position: "absolute",
                      // bottom: -10,
                      // left: -20,
                      // height: 40,
                      // width: 40,
                      // marginTop: 30,
                      // borderRadius: 20,
                      cursor: "pointer",
                      fontSize: 28,
                      backgroundColor: "transparent",
                      color: "red",
                    }}
                    onClick={() => {
                      onRemoveItem(id);
                    }}
                  />
                </IconButton>
              </div>
            </div>
            <Hidden smUp>
              <div>
                <h2
                  className="textCenter"
                  style={{ marginTop: 0, marginBottom: 0 }}
                >
                  {name}
                </h2>
                {by ? (
                  <h4
                    className="textCenter"
                    style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    {config?.cart_item.by} {by}
                  </h4>
                ) : null}
              </div>
            </Hidden>
            <Grid item xs={6} sm={3} md={4} style={{}}>
              <Hidden xsDown>
                <h2
                  className="textCenter"
                  style={{ marginTop: 0, marginBottom: 0 }}
                >
                  {name}
                </h2>
                {by ? (
                  <h4
                    className="textCenter"
                    style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    {config?.cart_item.by} {by}
                  </h4>
                ) : null}
              </Hidden>
              <h4
                className="textCenter"
                style={{ marginTop: 5, marginBottom: 5 }}
              >
                SR {price}/{config?.cart_item.pcs}
              </h4>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <h4
              className="textCenter"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              {selectedSize} - {quantity} pc
            </h4>
            <h4
              className="textCenter"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              Color: {color}
            </h4>
            <h4
              className="textCenter"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              Print: {printSide} side
            </h4>
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <h5 style={{ marginTop: 5, marginBottom: 5 }}>
              {config.cart_item.total}
            </h5>
            <h4 style={{ marginTop: 5, marginBottom: 5 }}>
              SR {price * quantity}
            </h4>
          </Grid>

          <div className="closeMD">
            <Grid item xs={6} sm={2} md={1} style={{}}>
              <IconButton aria-label="delete">
                <EditOutlined
                  style={{
                    cursor: "pointer",
                    fontSize: 28,
                    backgroundColor: "transparent",
                    color: "primary",
                  }}
                  onClick={() => {
                    onRemoveItem(id);
                  }}
                />
              </IconButton>
              <IconButton aria-label="delete">
                <DeleteOutlined
                  style={{
                    cursor: "pointer",
                    fontSize: 28,
                    backgroundColor: "transparent",
                    color: "red",
                  }}
                  onClick={() => {
                    onRemoveItem(id);
                  }}
                />
              </IconButton>
            </Grid>
          </div>
        </Grid>
      </div>
      {/* </Grid> */}
    </>
  );
}

export default CartItem;
