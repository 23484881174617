// import hero_background from "../assets/Scene-01.webp";
import logo from "../assets/LogoWithName.png";
const company_name = "UrbanKlozet";
// const providers= ["google", "facebook", "twitter"]
const providers: string[] = [];
const landingPage = {
  HeroSection: {
    bg: "white",
    textColor: "light",
    size: "md",
    bgImage:
      "https://www.detpak.com/globalassets/detpak/images/lifestyle/mobile-header-images-2/prod_mob_pbc-w2_detpak_plates.jpg",
    // "https://wallpapercave.com/wp/wp4316528.jpg",
    // bgImage: hero_background,
    bgImageOpacity: 1,
    title: "Awesome Sickers",
    subtitle:
      "This landing page is perfect for showing off your awesome product and driving people to sign up for a paid plan.",
    buttonText: "Buy now",
    buttonColor: "primary",
    // image: "https://uploads.divjoy.com/undraw-japan_ubgk.svg",
    buttonPath: "/pricing",
  },
  ClientsSection: {
    bg: "light",
    textColor: "dark",
    size: "sm",
    bgImage: "",
    bgImageOpacity: 1,
    title: "Clients",
    subtitle: "",
  },
  FeaturesSection: {
    bg: "white",
    textColor: "dark",
    size: "md",
    bgImage: "",
    bgImageOpacity: 1,
    title: "Features",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.",
  },
  TestimonialsSection: {
    bg: "light",
    textColor: "dark",
    size: "md",
    bgImage: "",
    bgImageOpacity: 1,
    title: "Here's what people are saying",
    subtitle: "",
  },
  NewsletterSection: {
    bg: "white",
    textColor: "dark",
    size: "md",
    bgImage: "",
    bgImageOpacity: 1,
    title: "Stay in the know",
    subtitle: "Receive our latest articles and feature updates",
    buttonText: "Subscribe",
    buttonColor: "primary",
    inputPlaceholder: "Enter your email",
    subscribedMessage: "You are now subscribed!",
  },
};
const notFound = {
  thePage: "The page ",
  notFound: "could not be found",
};
const pricingPage = {
  bg: "white",
  textColor: "dark",
  size: "md",
  bgImage: "",
  bgImageOpacity: 1,
};
const settingsPage = {
  bg: "white",
  textColor: "dark",
  size: "md",
  bgImage: "",
  bgImageOpacity: 1,
};
const content_cards = [
  {
    image: "https://source.unsplash.com/aHrxrT1q2h0/800x600",
    title: "Faucibus turpis in",
    body: "Purus semper eget duis at tellus at urna condimentum mattis. Non blandit massa enim nec.",
    url: "/post/golden-gate",
  },
  {
    image: "https://source.unsplash.com/BkmdKnuAZtw/800x600",
    title: "Faucibus turpis in",
    body: "Purus semper eget duis at tellus at urna condimentum mattis. Non blandit massa enim nec.",
    url: "/post/beach",
  },
  {
    image: "https://source.unsplash.com/3XN-BNRDUyY/800x600",
    title: "Faucibus turpis in",
    body: "Purus semper eget duis at tellus at urna condimentum mattis. Non blandit massa enim nec.",
    url: "/post/road",
  },
  {
    image: "https://source.unsplash.com/eOcyhe5-9sQ/800x600",
    title: "Faucibus turpis in",
    body: "Purus semper eget duis at tellus at urna condimentum mattis. Non blandit massa enim nec.",
    url: "/post/ballons",
  },
];
const clients = [
  {
    name: "Instagram",
    logo: require("../assets/logo-instagram.svg"),
    width: "150px",
  },
  {
    name: "Slack",
    logo: require("../assets/logo-slack.svg"),
    width: "135px",
  },
  {
    name: "Tinder",
    logo: require("../assets/logo-tinder.svg"),
    width: "90px",
  },
  {
    name: "Spotify",
    logo: require("../assets/logo-spotify.svg"),
    width: "135px",
  },
];
const contact = {
  Name: "Name",
  NameRequired: "Your message has been sent!",
  Email: "Email",
  EmailRequired: "Please enter your email",
  Message: "Message",
  MessageRequired: "Please enter a message",
  Sending: "Sending...",
  Title: "Contact Us",
  SendMessage: "Send message",
  messageSent: "Thank you",
};
const theme = {
  default_bg_color: "light",
  default_text_color: "dark",
};
const social_links = [
  {
    url: "https://twitter.com",
    icon: "fab fa-twitter",
  },
  {
    url: "https://facebook.com",
    icon: "fab fa-facebook-f",
  },
  {
    url: "https://instagram.com",
    icon: "fab fa-instagram",
  },
];
const footer_config = {
  bg: theme.default_bg_color,
  textColor: theme.default_text_color,
  size: "sm",
  bgImage: "",
  bgImageOpacity: 1,
  description: "A short description of what you do here",
  copyright: "all rights reserved " + company_name + " © 2020 ",
  logo,
  links: [
    { path: "/about", text: "About" },
    { path: "/faq", text: "FAQ" },
    { path: "/contact", text: "Contact" },
  ],
  social_links: social_links,
};
const auth_config = {
  allTypeValues: {
    signin: {
      // Top title
      title: "Welcome back",
      // Submit button text
      buttonText: "Sign in",
      // Link text to other auth types
      linkTextSignup: "Create an account",
      linkTextForgotpass: "Forgot Password?",
      forgetPasswordSent: "Password reset email sent",
      passwordChanged: "Your password has been changed",
      emailRequired: "Please enter an email",
      passwordRequired: "Please enter a password",
      confirmPass: "Confirm Password",
      enterPassAgain: "Please enter your password again",
      passNotMatched: "This doesn't match your password",
      loading: "Loading...",
      lastUsed: "Last used",
      password: "Password",
      enterPass: "Please enter your password",
      submit: "Submit",
      OR: "OR",
    },
    signup: {
      title: "Get yourself an account",
      buttonText: "Sign up",
      linkTextSignin: "Sign in",
      haveAnAccount: "Have an account already?",
    },
    forgotpass: {
      title: "Get a new password",
      buttonText: "Reset password",
    },
    changepass: {
      title: "Choose a new password",
      buttonText: "Change password",
    },
  },
  providerDisplayNames: {
    google: "Google",
    facebook: "Facebook",
    twitter: "Twitter",
    github: "GitHub",
  },
};
const faq = {
  title: "Frequently Asked Questions",
  subtitle: "",
  items: [
    {
      question: "Integer ornare neque mauris?",
      answer:
        "Integer ornare neque mauris, ac vulputate lacus venenatis et. Pellentesque ut ultrices purus. Suspendisse ut tincidunt eros. In velit mi, rhoncus dictum neque a, tincidunt lobortis justo.",
    },
    {
      question: "Lorem ipsum dolor sit amet?",
      answer:
        "Nunc nulla mauris, laoreet vel cursus lacinia, consectetur sit amet tellus. Suspendisse ut tincidunt eros. In velit mi, rhoncus dictum neque a, tincidunt lobortis justo.",
    },
    {
      question: "Suspendisse ut tincidunt?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lobortis, metus et mattis ullamcorper. Suspendisse ut tincidunt eros. In velit mi, rhoncus dictum neque a, tincidunt lobortis justo.",
    },
    {
      question: "Ut enim ad minim veniam?",
      answer:
        "Suspendisse ut tincidunt eros. In velit mi, rhoncus dictum neque a, tincidunt lobortis justo.",
    },
    {
      question: "In velit mi, rhoncus dictum neque?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.",
    },
  ],
};
const features = [
  {
    title: "Explore",
    description:
      "Integer ornare neque mauris, ac vulputate lacus venenatis et. Pellentesque ut ultrices purus.",
    image: "https://uploads.divjoy.com/undraw-mind_map_cwng.svg",
  },
  {
    title: "Explore",
    description:
      "Integer ornare neque mauris, ac vulputate lacus venenatis et. Pellentesque ut ultrices purus.",
    image: "https://uploads.divjoy.com/undraw-personal_settings_kihd.svg",
  },
  {
    title: "Explore",
    description:
      "Integer ornare neque mauris, ac vulputate lacus venenatis et. Pellentesque ut ultrices purus.",
    image: "https://uploads.divjoy.com/undraw-having_fun_iais.svg",
  },
  {
    title: "Explore",
    description:
      "Integer ornare neque mauris, ac vulputate lacus venenatis et. Pellentesque ut ultrices purus.",
    image: "https://uploads.divjoy.com/undraw-balloons_vxx5.svg",
  },
];
const newsletter = {
  Email: "Email",
  EmailRequired: "Please enter an email address",
  Subscribe: "Subscribe",
  title: "Stay in the know",
  subtitle: "Receive our latest articles and feature updates",
  buttonText: "Subscribe",
  buttonColor: "",
  inputPlaceholder: "Enter your email",
  subscribedMessage: "You are now subscribed!",
};
const pricing = {
  items: [
    {
      id: "starter",
      name: "Starter",
      price: "10",
      perks: [
        "Lorem ipsum dolor sit amet",
        "Consectetur adipiscing elit",
        "Integer molestie lorem at massa",
      ],
      description: "",
    },
    {
      id: "pro",
      name: "Pro",
      price: "20",
      description: "",
      perks: [
        "Lorem ipsum dolor sit amet",
        "Consectetur adipiscing elit",
        "Integer molestie lorem at massa",
        "Faucibus porta lacus fringilla vel",
        "Aenean sit amet erat nunc",
      ],
    },
    {
      id: "business",
      name: "Business",
      price: "50",
      description: "",
      perks: [
        "Lorem ipsum dolor sit amet",
        "Consectetur adipiscing elit",
        "Integer molestie lorem at massa",
        "Faucibus porta lacus fringilla vel",
        "Aenean sit amet erat nunc",
        "Lorem ipsum dolor sit amet",
        "Consectetur adipiscing elit",
      ],
    },
  ],
  title: "Pricing",
  subtitle:
    "Choose the plan that makes sense for you. All plans include a 14-day free trial.",
};
const settings = {
  profile_updated: "Your profile has been updated",
  Name: "Name",
  NameRequired: "Your message has been sent!",
  Email: "Email",
  EmailRequired: "Please enter your email",
  Save: "Save",
  Sending: "Sending...",
  Nav: {
    items: [
      {
        path: "/settings/general",
        type: "link",
        name: "General",
      },
      { path: "/settings/password", type: "link", name: "Password" },
      { path: "/settings/billing", type: "link", name: "Billing" },
    ],
  },
  PasswordSuccess: "Your password has been updated",
  Password: "Password",
  PasswordLabel: "New Password",
  PasswordRequired: "Please enter a password",
  ConfirmPasswordLabel: "Confirm New Password",
  ConfirmPassword: "Confirm Password",
  ConfirmPasswordRequired: "Please enter your new password again",
  PasswordNoMatch: "This doesn't match your password",
};
const stats = {
  items: [
    {
      title: "Tweets",
      stat: "3,456",
    },
    {
      title: "Following",
      stat: "123",
    },
    {
      title: "Followers",
      stat: "456k",
    },
    {
      title: "Likes",
      stat: "789",
    },
  ],
};
const team = {
  items: [
    {
      avatar: "https://uploads.divjoy.com/pravatar-150x-68.jpeg",
      name: "John Smith",
      role: "Software Engineer",
      bio: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo.",
    },
    {
      avatar: "https://uploads.divjoy.com/pravatar-150x-35.jpeg",
      name: "Lisa Zinn",
      role: "Software Engineer",
      bio: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo, corporis totam! Labore reprehenderit beatae magnam animi!",
    },
    {
      avatar: "https://uploads.divjoy.com/pravatar-150x-16.jpeg",
      name: "Diana Low",
      role: "Designer",
      bio: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo, corporis totam! Labore reprehenderit beatae magnam animi!",
    },
  ],
};
const testimonials = {
  items: [
    {
      avatar: "https://uploads.divjoy.com/pravatar-150x-5.jpeg",
      name: "Sarah Kline",
      testimonial:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.",
      company: "Company",
    },
    {
      avatar: "https://uploads.divjoy.com/pravatar-150x-48.jpeg",
      name: "Shawna Murray",
      testimonial:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo, corporis totam!",
      company: "Company",
    },
    {
      avatar: "https://uploads.divjoy.com/pravatar-150x-12.jpeg",
      name: "Blake Elder",
      testimonial:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae.",
      company: "Company",
    },
  ],
};
const about = {
  heroTitle: "We help you make money",
  heroSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo, corporis totam!",
  meetTheTeam: "Meet the Team",
};
const dashboard = {
  Title: "Dashboard",
};
const firebase_actions = {
  recoverEmail1: "Your email has been set back to ",
  recoverEmail2:
    ". We've also sent you a password reset email so that you can change your password if you think someone may have access to your account.",
  emailVerified: "Your email has been verified. You may now close this window.",
};
const navbar = {
  logged_in: {
    items: [
      {
        type: "dropdown",
        name: "Account",
        items: [
          { path: "/dashboard", type: "link", name: "Dashboard" },
          { path: "/billing", type: "link", name: "Billing" },
          { path: "/settings/general", type: "link", name: "Settings" },
          { type: "divider" },
          {
            path: "/auth/signout",
            type: "link",
            action: "sign_out",
            name: "Sign out",
          },
        ],
      },
      {
        path: "/cart",
        type: "link",
        name: "Cart",
        icon: "fas fa-shopping-bag",
      },
      {
        path: "/products",
        type: "link",
        name: "Products",
      },
      {
        path: "/orders",
        type: "link",
        name: "Orders",
      },
      {
        path: "/dashboard",
        type: "link",
        name: "Dashboard",
      },
    ],
  },
  logged_out: {
    items: [
      {
        path: "/cart",
        type: "link",
        name: "Cart",
        icon: "fas fa-shopping-bag",
      },
      { path: "/auth/signin", type: "link", name: "Sign In" },
    ],
  },
};
const checkout = {
  title: "Checkout",
  subtitle: "Checkout Subtitle",
  // logo: "logo",
  side_cart_title: "Your Cart",
  currency: "SAR",
  total: "Total",
  total_amount: 40,
  cart_products: [
    {
      name: "Product Name",
      description: "product description",
      price: 10,
      currency: "sar",
      quantity: 1,
    },
    {
      name: "Product Two",
      description: "product description",
      price: 10,
      currency: "sar",
      quantity: 1,
    },
    {
      name: "Product Name",
      description: "product description",
      price: 10,
      currency: "sar",
      quantity: 1,
    },
  ],
  promo: {
    title: "Promo code",
    code_name: "promo1",
    redeem: "Redeem",
    currency: "SAR",
    discount_amount: 10,
  },
  tax: {
    percentage: "15%",
    name: "VAT",
  },
  Address: {
    billing_address: "Billing Address",
    shipping_address: "Shipping Address",
    firs_name: "First name",
    last_name: "Last name",
    email: "Email",
    address: "Address",
    address2: "Address 2",
    optional: "(Optional)",
    country: "Country",
    state: "State",
    city: "City",
    shipping_address_same: "Shipping address is the same as my billing address",
    save_info: "Save this information for next time",
  },
  Payment: {
    title: "Payment",
    payment_options: [
      { type: "credit_cart", name: "Credit card", icon: "" },
      { type: "debit_cart", name: "Debit card", icon: "" },
      { type: "paypal", name: "Paypal", icon: "" },
      { type: "Mada", name: "Mada", icon: "" },
      { type: "apple_pay", name: "Apple Pay", icon: "" },
    ],
    name_on_card: "Name on card",
    name_on_card_hint: "Full name as displayed on card",
    credit_card_number: "Credit card number",
    expiration: "Expiration",
    cvv: "CVV",
  },
  check_out_button: "Continue to checkout",
};

const productCard_config = {
  prod: {
    name: "Product Name",
    image: "https://source.unsplash.com/aHrxrT1q2h0/800x600",
    price: 100,
    isOutOfStock: false,
  },
};
const productCard_config2 = {
  prod: {
    brandName: "Brand Name",
    name: "Product Name",
    image:
      "https://images.unsplash.com/photo-1518077738226-1607ea7f1846?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
    price: 149,
    beforePrice: 199,
    isOutOfStock: false,
  },
};

const sticker_form = {
  by: "By",
  piece: "Piece",
  selectSize: "Select Size",
  selectQuantity: "Select Quantity",
  addToCart: "Add To Cart",
};
const cart_item = {
  by: "By",
  pcs: "Pcs",
  size: "Size",
  quantity: "Quantity",
  total: "Total",
};

///
const default_config = {
  company_name,
  providers,
  logo,
  footer_config,
  social_links,
  auth_config,
  clients,
  contact,
  content_cards,
  faq,
  features,
  navbar,
  newsletter,
  pricing,
  settings,
  stats,
  team,
  testimonials,
  about,
  dashboard,
  firebase_actions,
  landingPage,
  notFound,
  pricingPage,
  settingsPage,
  checkout,
  productCard_config,
  productCard_config2,
  sticker_form,
  cart_item,
};
export default default_config;
