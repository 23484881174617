import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Button, makeStyles } from "@material-ui/core";
import { createAddress } from "../../../config/CreateAddress";
import Loader from "../../Reusable/Loader";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      overflow: "hidden",
      marginBottom: 20,
      padding: 50,
      paddingTop: 20,
      [theme.breakpoints.down("sm")]: {
        padding: 20,
      },
    },
  };
});

const AddressForm = (props) => {
  const classes = useStyles();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [data, setData] = useState<object>({
    firstName: "",
    lastName: "",
    mobile: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "India",
  }) as any;

  const [errors, setErrors] = useState<object>({
    firstName: false,
    lastName: false,
    mobile: false,
    address1: false,
    address2: false,
    city: false,
    state: false,
    zip: false,
    country: "India",
  }) as any;

  const addNewAddress = async () => {
    setIsSubmitting(true);
    console.log(data);
    const errors = await validateData();
    if (Object.keys(errors).length > 0) {
      setIsSubmitting(false);
    } else {
      const result: any = await createAddress(data, props.uid);
      console.log(result);
      if (result === "err") {
        setIsSubmitting(false);
        toast.error("Something went wrong, please try again later");
      } else {
        props.userReducer({ action: "GET_ADDRESS", payload: null });
        props.setPanel(false);
        setData({
          firstName: "",
          lastName: "",
          mobile: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          country: "India",
        });
        setIsSubmitting(false);
      }
    }
  };
  const validateData = () => {
    let newErrors: any = {};
    //SignUp
    if (data.firstName === "") {
      newErrors.firstName = true;
    }
    if (data.lastName === "") {
      newErrors.lastName = true;
    }
    if (data.mobile === "") {
      newErrors.mobile = true;
    }
    if (data.address1 === "") {
      newErrors.address1 = true;
    }
    if (data.city === "") {
      newErrors.city = true;
    }
    if (data.state === "") {
      newErrors.state = true;
    }
    if (data.zip === "") {
      newErrors.zip = true;
    }

    setErrors(newErrors);
    return newErrors;
  };

  const onChangeData = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <React.Fragment>
      <Loader text="Creating New Address" loading={isSubmitting} />
      <h1 style={{ textAlign: "center", fontWeight: "bold", marginTop: 50 }}>
        Add New Address
      </h1>

      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={errors?.firstName}
            id="firstName"
            name="firstName"
            label="First name"
            value={data?.firstName}
            onChange={onChangeData}
            fullWidth
            autoComplete="given-name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={errors?.lastName}
            value={data?.lastName}
            id="lastName"
            name="lastName"
            label="Last name"
            onChange={onChangeData}
            fullWidth
            autoComplete="family-name"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            type="tel"
            value={data?.mobile}
            error={errors?.mobile}
            id="mobile"
            name="mobile"
            label="Mobile No"
            onChange={onChangeData}
            fullWidth
            autoComplete="mobile"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            value={data?.address1}
            error={errors?.address1}
            id="address1"
            name="address1"
            label="Address line 1"
            onChange={onChangeData}
            fullWidth
            autoComplete="shipping address-line1"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            value={data?.address2}
            name="address2"
            label="Address line 2"
            onChange={onChangeData}
            fullWidth
            autoComplete="shipping address-line2"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={data?.city}
            error={errors?.city}
            id="city"
            name="city"
            label="City"
            onChange={onChangeData}
            fullWidth
            autoComplete="shipping address-level2"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={data?.state}
            error={errors?.state}
            id="state"
            name="state"
            onChange={onChangeData}
            label="State/Province/Region"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={data?.zip}
            error={errors?.zip}
            id="zip"
            name="zip"
            onChange={onChangeData}
            label="Zip / Postal code"
            fullWidth
            autoComplete="shipping postal-code"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="country"
            name="country"
            label="Country"
            value={data?.country}
            disabled
            onChange={onChangeData}
            fullWidth
            autoComplete="shipping country"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            color="primary"
            disabled={isSubmitting}
            onClick={addNewAddress}
            style={{
              marginTop: 20,
              height: 50,
              borderRadius: 25,
            }}
          >
            Add Address
          </Button>
          {/* <FormControlLabel
            control={
              <Checkbox color="primary" name="saveAddress" value="yes" />
            }
            label="Save this for future use"
          /> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddressForm;
