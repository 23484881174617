import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/lab components
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Pagination from "@material-ui/lab/Pagination";
// @material-ui/icons components
import MoreVert from "@material-ui/icons/MoreVert";

// core components
import Header from "../Headers/Header";
import boxShadows from "../../util/box-shadow";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
  },
  cardRootDark: {
    backgroundColor: theme.palette.dark.main,
    "& *": {
      color: theme.palette.white.main,
    },
    "& $tableCellRoot, & $tableRoot": {
      color: theme.palette.white.main,
      borderColor: theme.palette.dark.tableBorder,
    },
    "& $tableCellRootHead": {
      color: theme.palette.dark.tableHeadColor,
      backgroundColor: theme.palette.dark.tableHeadBgColor,
    },
  },
  cardHeader: {
    backgroundColor: "initial",
  },
  cardActionsRoot: {
    paddingBottom: "1.5rem!important",
    paddingTop: "1.5rem!important",
    borderTop: "0!important",
  },
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "39px",
      paddingRight: "39px",
    },
  },
  tableRoot: {
    marginBottom: "0!important",
  },
  tableCellRoot: {
    verticalAlign: "middle",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    borderTop: "0",
  },
  tableCellRootHead: {
    backgroundColor: theme.palette.gray[100],
    color: theme.palette.gray[600],
  },
  tableCellRootBodyHead: {
    textTransform: "unset!important",
    fontSize: ".8125rem",
  },
  linearProgressRoot: {
    height: "3px!important",
    width: "120px!important",
    margin: "0!important",
  },
  bgGradientError: {
    background:
      "linear-gradient(87deg," +
      theme.palette.error.main +
      ",#f56036)!important",
  },
  bgGradientSuccess: {
    background:
      "linear-gradient(87deg," +
      theme.palette.success.main +
      ",#2dcecc)!important",
  },
  bgGradientPrimary: {
    background:
      "linear-gradient(87deg," +
      theme.palette.primary.main +
      ",#825ee4)!important",
  },
  bgGradientInfo: {
    background:
      "linear-gradient(87deg," +
      theme.palette.info.main +
      ",#1171ef)!important",
  },
  bgGradientWarning: {
    background:
      "linear-gradient(87deg," +
      theme.palette.warning.main +
      ",#fbb140)!important",
  },
  bgPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  bgPrimaryLight: {
    backgroundColor: theme.palette.primary.light,
  },
  bgError: {
    backgroundColor: theme.palette.error.main,
  },
  bgErrorLight: {
    backgroundColor: theme.palette.error.light,
  },
  bgWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  bgWarningLight: {
    backgroundColor: theme.palette.warning.light,
  },
  bgInfo: {
    backgroundColor: theme.palette.info.main,
  },
  bgInfoLight: {
    backgroundColor: theme.palette.info.light,
  },
  bgSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  verticalAlignMiddle: {
    verticalAlign: "middle",
  },
  avatarRoot: {
    width: "36px",
    height: "36px",
    fontSize: ".875rem",
  },
}));

const TableComponent = (props) => {
  //Props
  const {
    loading,
    tableTitle,
    onNext,
    onPrevious,
    columns,
    data,
    values,
    buttonText,
    buttonLink,
    orders,
    nextDisabled,
    prevDisabled,
    idKey,
    firebase,
    requests,
    onRefreshClick,
    openInDrawer,
    setDrawerVisible,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl6, setAnchorEl6] = React.useState(null);
  const handleClick = (event) => {
    switch (event.currentTarget.getAttribute("aria-controls")) {
      case "simple-menu-1":
        setAnchorEl1(event.currentTarget);
        break;
      case "simple-menu-6":
        setAnchorEl6(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleClose = () => {
    setAnchorEl1(null);
    setAnchorEl6(null);
  };

  const renderTableCell = (tableData, fieldName, index) => {
    if (fieldName === "email") {
      return (
        <TableCell
          classes={{
            root: classes.tableCellRoot + " " + classes.tableCellRootBodyHead,
          }}
          component="th"
          variant="head"
          scope="row"
          key={index.toString()}
        >
          <Box alignItems="center" display="flex">
            <Box
              component={Avatar}
              marginRight="1rem"
              alt="..."
              src={require("../../assets/check.png")}
            />
            <Box display="flex" alignItems="flex-start">
              <Box fontSize=".875rem" component="span">
                {tableData[fieldName]}
              </Box>
            </Box>
          </Box>
        </TableCell>
      );
    } else if (fieldName === "status") {
      return (
        <TableCell
          classes={{ root: classes.tableCellRoot }}
          key={index.toString()}
        >
          <Box paddingTop=".20rem" paddingBottom=".20rem">
            <Box
              marginRight="10px"
              marginLeft="10px"
              component="i"
              width=".5rem"
              height=".5rem"
              borderRadius="50%"
              display="inline-block"
              // className={classes.verticalAlignMiddle + " " + classes.bgWarning}
              style={{
                backgroundColor:
                  tableData[fieldName] === "Rejected"
                    ? "#f5365c"
                    : tableData[fieldName] === "Pending"
                    ? "#ffeaa7"
                    : tableData[fieldName] === "InProcess"
                    ? "#fdcb6e"
                    : tableData[fieldName] === "Shipped"
                    ? "#0984e3"
                    : tableData[fieldName] === "Delivered"
                    ? "#00b894"
                    : "white",
              }}
            ></Box>
            {tableData[fieldName]}
          </Box>
        </TableCell>
      );
    } else if (fieldName === "createdAt") {
      return (
        <TableCell
          classes={{ root: classes.tableCellRoot }}
          key={index.toString()}
        >
          {tableData[fieldName]?.toDate().toLocaleString()}
        </TableCell>
      );
    } else if (fieldName === "lineItems") {
      return (
        <TableCell
          classes={{ root: classes.tableCellRoot }}
          key={index.toString()}
        >
          <AvatarGroup>
            {tableData[fieldName].map((i) => {
              return (
                <Avatar
                  classes={{ root: classes.avatarRoot }}
                  alt="..."
                  src={i?.product?.images[0] ? i.product.images[0] : i ? i : ""}
                />
              );
            })}
          </AvatarGroup>
        </TableCell>
      );
    } else if (fieldName === "userData") {
      return (
        <TableCell
          classes={{ root: classes.tableCellRoot }}
          key={index.toString()}
        >
          {tableData[fieldName]?.name}
        </TableCell>
      );
    } else if (fieldName === "bill") {
      return (
        <TableCell
          classes={{ root: classes.tableCellRoot }}
          key={index.toString()}
        >
          Rs. {tableData[fieldName]?.grandTotal}
        </TableCell>
      );
    } else {
      return (
        <TableCell
          classes={{ root: classes.tableCellRoot }}
          key={index.toString()}
        >
          {tableData[fieldName]}
        </TableCell>
      );
    }
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          {!loading ? (
            !data.length > 0 ? (
              <CardHeader
                className={classes.cardHeader}
                title="No Order Found"
                titleTypographyProps={{
                  component: Box,
                  marginBottom: "0!important",
                  variant: "h3",
                }}
              ></CardHeader>
            ) : (
              <>
                <CardHeader
                  className={classes.cardHeader}
                  title={tableTitle}
                  titleTypographyProps={{
                    component: Box,
                    marginBottom: "0!important",
                    variant: "h3",
                  }}
                ></CardHeader>
                <TableContainer>
                  <Box
                    component={Table}
                    alignItems="center"
                    marginBottom="0!important"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((item, index) => (
                          <TableCell
                            classes={{
                              root:
                                classes.tableCellRoot +
                                " " +
                                classes.tableCellRootHead,
                            }}
                          >
                            {item.name}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((item, index) => {
                        let tableData = item;
                        let url = buttonLink + tableData[idKey];
                        if (requests) {
                          url =
                            buttonLink +
                            tableData.type +
                            "/" +
                            tableData[idKey];
                        }
                        return (
                          <>
                            <TableRow>
                              {values.map((fieldName, index) => {
                                return renderTableCell(
                                  tableData,
                                  fieldName?.value,
                                  index
                                );
                              })}
                              {url && buttonText && (
                                <TableCell
                                  classes={{ root: classes.tableCellRoot }}
                                  align="right"
                                >
                                  <Link
                                    to={{ pathname: url, state: tableData }}
                                  >
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                    >
                                      <h5 style={{ margin: 0, padding: 0 }}>
                                        {buttonText}
                                      </h5>
                                    </Button>
                                  </Link>
                                </TableCell>
                              )}
                              {/* 
                              <TableCell
                                classes={{ root: classes.tableCellRoot }}
                                align="right"
                              >
                                <Box
                                  aria-controls="simple-menu-1"
                                  aria-haspopup="true"
                                  onClick={handleClick}
                                  size="small"
                                  component={Button}
                                  width="2rem!important"
                                  height="2rem!important"
                                  minWidth="2rem!important"
                                  minHeight="2rem!important"
                                >
                                  <Box
                                    component={MoreVert}
                                    width="1.25rem!important"
                                    height="1.25rem!important"
                                    position="relative"
                                    top="2px"
                                    color={theme.palette.gray[500]}
                                  />
                                </Box>
                                <Menu
                                  id="simple-menu-1"
                                  anchorEl={anchorEl1}
                                  keepMounted
                                  open={Boolean(anchorEl1)}
                                  onClose={handleClose}
                                >
                                  <MenuItem onClick={handleClose}>
                                    Action
                                  </MenuItem>
                                  <MenuItem onClick={handleClose}>
                                    Another action
                                  </MenuItem>
                                  <MenuItem onClick={handleClose}>
                                    Something else here
                                  </MenuItem>
                                </Menu>
                              </TableCell> */}
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Box>
                </TableContainer>
                <Box
                  classes={{ root: classes.cardActionsRoot }}
                  component={CardActions}
                  justifyContent="center"
                >
                  Prev
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={onPrevious}
                    disabled={prevDisabled}
                  >
                    <ChevronLeft />
                  </IconButton>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={onNext}
                    disabled={nextDisabled}
                  >
                    <ChevronRight />
                  </IconButton>
                  Next
                </Box>
              </>
            )
          ) : (
            <CardHeader
              className={classes.cardHeader}
              title="Loading..."
              titleTypographyProps={{
                component: Box,
                marginBottom: "0!important",
                variant: "h3",
              }}
            ></CardHeader>
          )}
        </Card>

        {/* <Box
          component={Card}
          marginTop="3rem"
          classes={{ root: classes.cardRoot + " " + classes.cardRootDark }}
        >
          <CardHeader
            className={classes.cardHeader}
            title="Card Tables"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
          ></CardHeader>
          <TableContainer>
            <Box
              component={Table}
              alignItems="center"
              marginBottom="0!important"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    Project
                  </TableCell>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    Budget
                  </TableCell>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    Users
                  </TableCell>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    Completion
                  </TableCell>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="head"
                    scope="row"
                  >
                    <Box alignItems="center" display="flex">
                      <Box
                        component={Avatar}
                        marginRight="1rem"
                        alt="..."
                        src={require("../../assets/check.png")}
                      />
                      <Box display="flex" alignItems="flex-start">
                        <Box fontSize=".875rem" component="span">
                          Argon Design System
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    $2,500 USD
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem">
                      <Box
                        marginRight="10px"
                        component="i"
                        width=".375rem"
                        height=".375rem"
                        borderRadius="50%"
                        display="inline-block"
                        className={
                          classes.verticalAlignMiddle + " " + classes.bgWarning
                        }
                      ></Box>
                      pending
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <AvatarGroup>
                      <Tooltip title="Ryan Tompson" placement="top">
                        <Avatar
                          classes={{ root: classes.avatarRoot }}
                          alt="..."
                          src={require("../../assets/check.png")}
                        />
                      </Tooltip>
                      <Tooltip title="Romina Hadid" placement="top">
                        <Avatar
                          classes={{ root: classes.avatarRoot }}
                          alt="..."
                          src={require("../../assets/check.png")}
                        />
                      </Tooltip>
                      <Tooltip title="Alexander Smith" placement="top">
                        <Avatar
                          classes={{ root: classes.avatarRoot }}
                          alt="..."
                          src={require("../../assets/check.png")}
                        />
                      </Tooltip>
                      <Tooltip title="Jessica Doe" placement="top">
                        <Avatar
                          classes={{ root: classes.avatarRoot }}
                          alt="..."
                          src={require("../../assets/check.png")}
                        />
                      </Tooltip>
                    </AvatarGroup>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center">
                      <Box component="span" marginRight=".5rem">
                        60%
                      </Box>
                      <Box width="100%">
                        <LinearProgress
                          variant="determinate"
                          value={60}
                          classes={{
                            root: classes.linearProgressRoot,
                            bar: classes.bgGradientError,
                          }}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                    align="right"
                  >
                    <Box
                      aria-controls="simple-menu-6"
                      aria-haspopup="true"
                      onClick={handleClick}
                      size="small"
                      component={Button}
                      width="2rem!important"
                      height="2rem!important"
                      minWidth="2rem!important"
                      minHeight="2rem!important"
                    >
                      <Box
                        component={MoreVert}
                        width="1.25rem!important"
                        height="1.25rem!important"
                        position="relative"
                        top="2px"
                        color={theme.palette.gray[500]}
                      />
                    </Box>
                    <Menu
                      id="simple-menu-6"
                      anchorEl={anchorEl6}
                      keepMounted
                      open={Boolean(anchorEl6)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleClose}>Action</MenuItem>
                      <MenuItem onClick={handleClose}>Another action</MenuItem>
                      <MenuItem onClick={handleClose}>
                        Something else here
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Box>
          </TableContainer>
          <Box
            classes={{ root: classes.cardActionsRoot }}
            component={CardActions}
            justifyContent="center"
          >
            <Pagination count={3} color="primary" variant="outlined" />
          </Box>
        </Box>*/}
      </Container>
    </>
  );
};

export default TableComponent;
