import React from "react";
import { Box, Input, LinearProgress } from "@material-ui/core";
// import { Progress, div, Input } from "reactstrap";

const UploadFile = (props) => {
  const {
    placeholder,
    uploadLimit,
    showProgress,
    imageUrl,
    onChange,
    removeImage,
    uploading,
    uploadProgress,
  } = props;

  return (
    <>
      <Box alignItems="center" display="flex">
        <Box
          display="flex"
          justifyContent="space-between"
          className="d-flex justify-content-between"
        >
          {!uploadLimit && !showProgress ? (
            <label
              className="d-flex align-items-center"
              style={{
                height: 120,
                width: 120,
                padding: "auto",
                borderWidth: 2,
                borderStyle: "dashed",
                borderRadius: 10,
                margin: 10,
                cursor: "pointer",
                padding: 10,
              }}
            >
              {placeholder}
              <Input
                type="file"
                style={{ width: 0, height: 0, overflow: "hidden" }}
                id="exampleCustomFileBdivser"
                name="customFile"
                onChange={onChange}
              />
            </label>
          ) : null}
          {imageUrl.map((i, index) => {
            return (
              <div
                key={index}
                data-aos="fade-right"
                data-aos-easing="ease-in-out"
                style={{ position: "relative" }}
              >
                <div
                  style={{
                    margin: "auto",
                    width: 25,
                    height: 25,
                    color: "white",
                    backgroundColor: "black",
                    position: "absolute",
                    cursor: "pointer",
                    top: 10,
                    right: 10,
                    zIndex: 2,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    removeImage(index);
                  }}
                >
                  X
                </div>
                <img
                  className="shadow"
                  style={{
                    height: 120,
                    width: 120,
                    borderWidth: 1,
                    borderStyle: "solid",
                    objectFit: "contain",
                    borderRadius: 10,
                    margin: 10,
                    position: "relative",
                  }}
                  alt="..."
                  src={i}
                />
              </div>
            );
          })}
        </Box>
      </Box>

      <div>
        {showProgress ? (
          <div
            data-aos="fade-down"
            data-aos-easing="ease-in-out"
            className="progress-wrapper"
            style={{ width: "90%", marginLeft: "5%" }}
          >
            <div className="progress-info">
              <div className="progress-label">
                <span>{uploading}</span>
              </div>
              <div className="progress-percentage">
                <span>{uploadProgress}%</span>
              </div>
            </div>
            <LinearProgress variant="determinate" value={uploadProgress} />

            {/* <Progress max="100" value={uploadProgress} color="default" /> */}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default UploadFile;
