import React, { useEffect, useState } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { IoMdCloseCircle } from "react-icons/io";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";

const SidePanel = ({ isVisible, setIsVisible, children, maxWidth }) => {
  const useStyles = makeStyles((theme) => {
    return {
      panelContainer: {
        width: maxWidth ? maxWidth : "70vw",
        // overflow: "hidden",
        [theme.breakpoints.down("sm")]: {
          width: "100vw",
        },
        backgroundColor: "transparent",
        paddingBottom: 50,
      },
      closeBtn: {
        // width: 32,
        // height: 32,
        // backgroundColor: "white",
        borderRadius: "50%",
        display: isVisible ? "inline" : "none",
        position: "absolute",
        top: 20,
        right: maxWidth ? maxWidth + 15 : "70vw",
        cursor: "pointer",
        zIndex: 1400,
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    };
  });
  //Hooks
  const classes = useStyles();

  //Functions
  const toggleDrawer = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <SwipeableDrawer
        anchor={"right"}
        open={isVisible}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <div className={classes.panelContainer}>{children}</div>
      </SwipeableDrawer>
      <div className={classes.closeBtn} onClick={toggleDrawer}>
        <IconButton>
          <IoMdCloseCircle size="1.5em" color="white" />
        </IconButton>
      </div>
    </>
  );
};

export default SidePanel;
