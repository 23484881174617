import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import PricingPage from "./pricing";
import ContactPage from "./contact";
import DashboardPage from "./dashboard";
import SettingsPage from "./settings";
import PurchasePage from "./purchase";
import OrdersPage from "./orders";
import AuthPage from "./auth";
import { Switch, Route, Router } from "./../util/router.js";
import FirebaseActionPage from "./firebase-action.js";
import NotFoundPage from "./not-found.js";
import Footer from "./../components/Footer";
import "./../util/analytics.js";
import { AuthProvider } from "./../util/auth.js";
import { ThemeProvider } from "./../util/theme.js";
import AppContextProvider from "../config/context/AppContext";
import UserContextProvider from "../config/context/UserContext";
import ProductDetails from "./ProductDetails";
import CartPage from "./cart";
import Thankyou from "./Thankyou";
import { Toaster } from "react-hot-toast";

import LogoWithName from "../assets/LogoWithName.png";
import Logo from "../assets/Logo.png";

// // Import Swiper styles
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

function App(props) {
  return (
    <ThemeProvider>
      <AuthProvider>
        <AppContextProvider>
          <UserContextProvider>
            <Router>
              <>
                <Navbar color="default" logo={Logo} logoInverted={Logo} />
                <Toaster />
                <Switch>
                  <Route exact path="/" component={IndexPage} />

                  <Route exact path="/design/:id" component={ProductDetails} />

                  <Route exact path="/orders" component={OrdersPage} />

                  <Route exact path="/about" component={AboutPage} />

                  <Route exact path="/faq" component={FaqPage} />

                  <Route exact path="/pricing" component={PricingPage} />

                  <Route exact path="/contact" component={ContactPage} />

                  <Route exact path="/cart" component={CartPage} />
                  <Route exact path="/thankyou" component={Thankyou} />

                  {/* <Route exact path="/dashboard" component={DashboardPage} /> */}
                  <Route
                    path="/admin"
                    render={(props) => <DashboardPage {...props} />}
                  />

                  <Route
                    exact
                    path="/settings/:section"
                    component={SettingsPage}
                  />

                  <Route exact path="/purchase/:plan" component={OrdersPage} />

                  <Route exact path="/auth/:type" component={AuthPage} />

                  <Route
                    exact
                    path="/firebase-action"
                    component={FirebaseActionPage}
                  />

                  <Route component={NotFoundPage} />
                </Switch>

                <Footer
                  bgColor="default"
                  size="medium"
                  bgImage=""
                  bgImageOpacity={1}
                  description="A short description of what you do here"
                  copyright={`© ${new Date().getFullYear()} Company`}
                  logo={LogoWithName}
                  logoInverted={LogoWithName}
                  sticky={true}
                />
              </>
            </Router>
          </UserContextProvider>
        </AppContextProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
