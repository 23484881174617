export const setLocalStorageItem = async (key, value) => {
  const newValue = await btoa(JSON.stringify(value));
  localStorage.setItem(key, newValue);
  return;
};

export const loadLocalStorageItem = async (key, cb) => {
  const data = await localStorage.getItem(key);
  if (data) {
    const value = await JSON.parse(atob(data));
    return value;
  }
  return null;
};

export const reomveLocalStorageItem = async (key, cb) => {
  localStorage.removeItem(key);
  return;
};
