import React, { useEffect, useState } from "react";
import check from "../assets/check.png";
import { UserContext } from "../config/context/UserContext";
import { AppContext } from "../config/context/AppContext";
import { Divider, Grid, Paper, Typography, Button } from "@material-ui/core";
import { useRouter } from "../util/router";

export interface ThankyouProps {}

const USE_EXISTING_CHECKOUT_INSTANCE = "Use existing checkout instance : ";

function Thankyou(props: ThankyouProps) {
  const { userState, userReducer } = React.useContext(UserContext) as any;
  const { appState, appReducer } = React.useContext(AppContext) as any;
  const router = useRouter();
  const [token, setToken] = useState<any>(null);
  const [showCheckout, setShowCheckout] = useState<boolean>(false);
  const [checkoutJsInstance, setCheckoutJsInstance] = useState<any>(null);

  useEffect(() => {
    userReducer({ action: "GET_ORDERS", payload: null });
  }, []);

  if (appState?.orderedItems.length <= 0) {
    return (
      <div
        style={{
          marginTop: 100,
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 style={{ textAlign: "center" }}>Are you lost?</h1>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            router.push("/");
          }}
          style={{ height: 40, width: 200, borderRadius: 20 }}
        >
          Go Home
        </Button>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          marginTop: 50,
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={check}
          style={{
            height: 100,
          }}
          alt="Order Confirmed"
        />
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          Order Confirmed
        </h1>
        <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
          Order # 12321
        </h2>
        <h2 style={{ textAlign: "center", fontWeight: "bold", marginTop: 0 }}>
          Thankyou for ordering with us, you will be receiving a confirmation
          email soon.
        </h2>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            router.push("/orders");
          }}
          style={{ height: 40, width: 200, borderRadius: 20 }}
        >
          View Orders
        </Button>
      </div>
    </>
  );
}

export default Thankyou;
