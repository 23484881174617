import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
// import CartItem from "../components/Ecommerce/CartItem/CartItem";
import ProductImages from "../components/Ecommerce/ProductImages/ProductImages";
import ProductForm from "../components/Ecommerce/ProductForm/ProductForm";
import { useLocation, useParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import ProductImages2 from "../components/Ecommerce/ProductImages2/ProductImages2";
import { getProductDetails, updateProductView } from "../config/ProductQueries";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { Blurhash } from "react-blurhash";
import ProductCarousel from "../components/Ecommerce/ProductCarousel/ProductCarousel";
import { AppContext } from "../config/context/AppContext";

export interface ProductDetailsPageProps {
  product: any;
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      margin: "auto",
      marginTop: 100,
      marginBottom: 50,
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: 50,
      },
    },
  };
});
const ProductDetails = (props: any) => {
  const { appState, appReducer } = React.useContext(AppContext) as any;

  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({}) as any;

  let classes: any = useStyles();
  let data: any = useParams();
  let { state }: any = useLocation();
  console.log(data, state, "productDetailsParams");

  useEffect(() => {
    if (state) {
      setProductData(state, "success");
    } else {
      getProductDetails(data?.id, setProductData);
    }
    updateProductView(data?.id);
  }, [data?.id]);

  const setProductData = (data: any, status: string) => {
    if (status === "success") {
      setProduct(data);
      setLoading(false);
    }
    if (status === "error") {
      console.log(data);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          height: "60vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div
      className={classes.root}
      style={
        {
          // borderWidth: 1,
          // borderColor: "grey",
          // borderStyle: "solid",
        }
      }
    >
      <Grid
        container
        justify="center"
        alignItems="center"
        className="blur"
        style={{
          padding: 20,
          paddingBottom: 50,
          borderRadius: 8,
          width: "90%",
          marginLeft: "5%",
        }}
      >
        <Grid item xs={12} sm={12} md={6}>
          <ProductImages2 images={product?.images} />
        </Grid>
        <Grid item xs={10} sm={10} md={4}>
          <ProductForm
            product={product}
            productId={product?.id}
            images={product?.images}
            name={product?.name}
            desc={product?.desc}
            designer={product?.designer}
            designerId={product?.designerId}
            price={product?.price}
            size={product?.size || []}
          />
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          margin: "auto",
          marginTop: 100,
          maxWidth: "70%",
        }}
        justify="center"
        alignItems="center"
      >
        <Grid container item sm={12} md={6} justify="center">
          <div>
            <h1
              style={{
                fontWeight: "bold",
                marginBottom: 0,
                marginTop: 0,
                paddingBottom: 0,
              }}
            >
              High quality materials
            </h1>

            <h4 style={{ maxWidth: 400 }}>
              Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
              aut fugit, sed quia consequuntur magni dolores eos qui ratione
              voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem
              ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia
              non numquam eius modi tempora incidunt ut labore.
            </h4>
          </div>
        </Grid>
        <Grid container item sm={12} md={6} justify="center">
          {/* <Blurhash
            hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
            width={400}
            height={300}
            style={{ borderRadius: 16 }}
            resolutionX={32}
            resolutionY={32}
            punch={1}
          /> */}
          <img
            className={""}
            alt="..."
            style={{
              maxWidth: 350,
              maxHeight: 400,
              borderRadius: 16,
              width: "100%",
            }}
            src={
              "https://images.unsplash.com/photo-1620799139834-6b8f844fbe61?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1332&q=80"
            }
          />
        </Grid>
      </Grid>

      {appState?.products.length > 0 ? (
        <>
          <h1
            style={{
              fontWeight: "bold",
              marginBottom: 0,
              marginTop: 60,
              textAlign: "center",
            }}
          >
            Similar Products
          </h1>
          <ProductCarousel products={appState?.products} />
        </>
      ) : null}
    </div>
  );
};

export default ProductDetails;
