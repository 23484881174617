import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { Spinner } from "reactstrap";

const Loader = (props) => {
  const { text, loading } = props;

  if (!loading) return null;

  return (
    <div
      style={{
        backgroundColor: "rgba(51,51,51,0.7)",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 99,
      }}
    >
      <div
        style={{
          zIndex: 1000,
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress size={40} color="secondary" />
        {/* <Spinner color="white" style={{ height: 80, width: 80 }} /> */}
        <span style={{ color: "white", fontSize: 35, marginTop: 10 }}>
          {text}
        </span>
      </div>
    </div>
  );
};

export default Loader;
